import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Chat from "../Chat";

import "./dashboard.css";
import { Button } from "@mui/base";
import Sidebar from "../Sidebar/Sidebar";
import Table from "../../genric/Table/Table";
import DashBoardCard from "./DashBoardCard";
import { Audio, Rings } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardUpdateData,
  fetchUser,
  getAllAppUserScanPid,
  getAllChargerHistory,
  getAllPids,
  getUserDetails,
  postPid,
} from "../../Redux/counterSlice";
import mqtt from "mqtt";
import Modal from "@mui/material/Modal";
import LineChart from "../History/linechart";
import PieChart from "../History/PieChart";
import { useNavigate } from "react-router-dom";
import {
  calculateAmountSum,
  calculateEnergySum,
} from "../../genric/getTotalEneryAndTotalRevenue/TotalEnergyRevenu";
import BarChart from "../History/chart";
import {
  getDateRangeDashboard,
  getDatesInRange,
  getEnergyUsedForDatesDashboard,
  getUniqueDatesDashboard,
} from "../../genric/labelforDashboard/label";
import RefreshButton from "../../genric/refreshIcon/RefreshIcon";
const Dashboard = () => {
  const dispatch = useDispatch();
  // const uid = "1223";
  const navigate = useNavigate();
  useEffect(() => {
    const stored = localStorage.getItem("user");
    console.log("stored", stored);
    if (!stored) {
      navigate("/");
    }
  }, []);
  const brokerUrl = "wss://mqtt.jouls.co.in/mqtt";

  // const brokerUrl = "wss://34.93.27.237:9001/mqtt";
  const [showAddChargerModal, setShowAddChargerModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [showModal, setShowModal] = useState(true);
  // const [apiCalledChargingData, setApiCalledChargingData] = useState(false);
  const [DummyDataforTable, setDummyDataforTable] = useState([1, 2, 3, 4, 5]);
  const [rtl, setRtl] = useState(false);
  const [search, setSearch] = useState("");
  const [alldata, setAlldata] = useState([
    { pid: "PES-A1B2C3D4E5F6G7H8I9", remark: "" },
  ]);
  const [CheckmqttMessagesReceived, setCheckmqttMessagesReceived] = useState(
    {}
  );
  const [
    CheckmqttMessagesReceivedCounter,
    setCheckmqttMessagesReceivedCounter,
  ] = useState({});
  // console.log("CheckmqttMessagesReceivedCounter",CheckmqttMessagesReceivedCounter);
  const stored = localStorage.getItem("user");
  const storedUsername = JSON.parse(stored);
  console.log(storedUsername, "lllllll");
  const [pidInput, setPidInput] = useState("");
  const [status, setStatus] = useState("");
  const [ShowActiveDevices, setShowActiveDevices] = useState(0);
  const [ShowTotalEnergy, setShowTotalEnergy] = useState(0);
  const [ShowTotalRevenue, setShowTotalRevenue] = useState(0);
  const [xlabel, setxlabel] = useState([]);
  const [ylabel, setylabel] = useState([]);
  const [StatusAndUser, SetStatusAndUser] = useState({
    chargerUsedBy: "",
    chargerStatus: "",
  });
  const pid = storedUsername?.userData;
  const uid = storedUsername?.userData?.uid;
  console.log(uid, "uiduid");
  const [showDropdown, setShowDropdown] = useState(pid);
  const [mqttClient, setMqttClient] = useState(null);

  const closeModal = () => {
    setShowModal(false);
  };
  // useEffect(() => {
  //   dispatch(fetchUser(uid));
  // }, []);
  const pidsData = useSelector((state) => state?.Store.getAllPids);
  console.log("pidsDatapidsData", pidsData);
  let AllPidDataWithMessages = useSelector(
    (state) => state.Store.allDashboardPidsMessages
  );
  let AllAppScanPids = useSelector(
    (state) => state.Store?.getAllAppUserScanPid
  );
  const getAllChargerHistoryData = useSelector(
    (state) => state.Store.getAllChargerHistory
  );
  console.log("getAllChargerHistoryData", AllPidDataWithMessages);

  const getLastAppUsernameByPid = (data, pid) => {
    if (!Array.isArray(data)) {
      return "Data Must Be Array";
    }
    const filteredArray = data.filter((item) => item.pid === pid);

    if (filteredArray.length === 0) {
      return { chargerStatus: "", UsedBy: "" };
    }

    const lastObject = filteredArray[filteredArray.length - 1];
    return {
      UsedBy: lastObject.UsedBy,
      chargerStatus: lastObject.chargerStatus,
    };
  };

  const fetchData = async (pid) => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        getUserDetails({
          uid,
          populateBankDetails: false,
          populateChargerHistoryData: true,
          populatePidsDetails: true,
        })
      );
      console.log(response, "responseresponse");
      setIsLoading(false);

      if (response.payload && pid) {
        return getLastAppUsernameByPid(response.payload.ChargerHistory, pid);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, uid]);

  useEffect(() => {
    const data = calculateEnergySum(getAllChargerHistoryData);
    const datesInRange = getDateRangeDashboard(getAllChargerHistoryData);
    const sum = calculateAmountSum(getAllChargerHistoryData);
    if (datesInRange.length > 0) {
      const ylabledata = getEnergyUsedForDatesDashboard(
        getAllChargerHistoryData,
        datesInRange
      );
      setylabel(ylabledata);
      console.log("datesInRangeylabledata", datesInRange, ylabledata);
    }
    setxlabel(datesInRange);
    setShowTotalEnergy(data);
    console.log("pidsDataasldf", getAllChargerHistoryData, sum);

    setShowTotalRevenue(sum);
    if (getAllChargerHistoryData.length > 0) {
      const chargerStatus =
        getAllChargerHistoryData[getAllChargerHistoryData.length - 1]
          .chargerStatus;
      const chargerUsedBy =
        getAllChargerHistoryData[getAllChargerHistoryData.length - 1].UsedBy;

      SetStatusAndUser((prevState) => ({
        ...prevState,
        chargerUsedBy: chargerUsedBy,
        chargerStatus: chargerStatus,
      }));
    }
  }, [getAllChargerHistoryData]);

  const handleConnect = () => {
    const client = mqtt.connect(brokerUrl);
    // Connection successful
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Subscribe to PID topic
      if (pidsData.length > 0) {
        console.log("in if revenuemangeagemn pidsData", pidsData);
        pidsData.forEach((e) => {
          const topics = [`${e.pid}_Updates`, `${e.pid}_Charging_Data`];
          topics.forEach((topic) => {
            client.subscribe(topic, (err) => {
              if (!err) {
                console.log("Subscribed to", topic);
              } else {
                console.error("Error subscribing to", topic, ":", err);
              }
            });
          });
        });
      } else {
        console.log("data nhi hai alldata me ", pidsData);
      }
      setMqttClient(client);
    });
  };

  useEffect(() => {
    handleConnect(); // Automatically connect on component mount
    if (!isLoading) {
      // Set showAddChargerModal based on pidsData length after loading is finished
      setShowAddChargerModal(pidsData.length === 0);
    }
  }, [pidsData]);

  const sendMessagesToTopics = () => {
    // MQTT client banaye
    const client = mqtt.connect(brokerUrl);

    // MQTT client connect hone ke baad
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Interval se messages send karein
      if (AllPidDataWithMessages.length > 0) {
        setInterval(() => {
          // Random message banaye
          const message = "IdleRadhe";
          // Har topic par message send karein
          AllPidDataWithMessages.forEach((topic) => {
            client.publish(`${topic.pid}_radhe`, message);
            console.log(`Message sent to topic ${topic}_radhe: ${message}`);
          });
        }, 1000); // Interval in milliseconds (2 seconds)
      } else {
        console.log("data nhi hai alldata me ");
      }
    });

    // MQTT client se disconnect hone ke baad
    client.on("offline", () => {
      console.log("Disconnected from MQTT broker");
    });

    // Cleanup function
    return () => {
      client.end(); // MQTT client ko cleanup karein
    };
  };

  // const lastMessages = new Map();

  const applyMqttLogic = async (topic, message) => {
    return await Promise.all(
      AllPidDataWithMessages.map(async (obj) => {
        if (`${obj?.pid}_Charging_Data` === topic.toString()) {
          try {
            const data = await fetchData(obj?.pid);
            console.log("datdatadaata", data);
            // If the API call is successful, return the data to be set
            // setApiCalledChargingData(true);
            return {
              ...obj,
              status: "Charging Started",
              AppUsername: data?.UsedBy || "Error",
            };
          } catch (error) {
            // If there's an error in the API call, handle it here
            console.error("Error in API call:", error);
            // You can return a default value or handle the error according to your needs
            return {
              ...obj,
              status: "Charging Started",
              AppUsername: "Unknown",
            };
          }
        }
        if (`${obj?.pid}_Updates` === topic.toString()) {
          // Handle logic for other topic
          if (message.toString() === "Idle") {
            setCheckmqttMessagesReceivedCounter({});
            return {
              ...obj,
              status: message.toString(),
              AppUsername: "No User",
            };
          }
          if (message.toString() === "Door is open") {
            setCheckmqttMessagesReceivedCounter({});
            return {
              ...obj,
              status: "Charger Connected",
              AppUsername: "No User",
            };
          }
          if (message.toString() === "Charging Started") {
            return {
              ...obj,
              status: "Charging Started",
              AppUsername: "Radhe ajmer",
            };
          }
          if (message.toString() === "Charging Completed") {
            setCheckmqttMessagesReceivedCounter({});
            return {
              ...obj,
              status: "Charging Completed",
              AppUsername: "No User",
            };
          }
        } else {
          // For other objects, return as is
          return obj;
        }
      })
    );
  };

  const handleMessageLogic = async (topic, message) => {
    try {
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === "Charging_Data"
      ) {
        // If the topic is "Charging_Data" and the message is "charging_data", check if it has been set twice
        if (
          CheckmqttMessagesReceivedCounter.hasOwnProperty(topic) &&
          CheckmqttMessagesReceivedCounter[topic] > 7
        ) {
          // If it has been set twice, return without updating the state
          return;
        }
      }

      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === message.toString()
      ) {
        return;
      }

      let mqttUpdatedProducts;

      if (topic.includes("Charging_Data")) {
        console.log(
          "CheckmqttMessagesReceivedCounter",
          CheckmqttMessagesReceivedCounter
        );
        if ((CheckmqttMessagesReceivedCounter[topic] || 0) === 5) {
          const mqttUpdatedProducts = await applyMqttLogic(topic, message);
          console.log("mqttUpdatedProducts radhe", mqttUpdatedProducts);
          dispatch(DashboardUpdateData(mqttUpdatedProducts));
        }
        setCheckmqttMessagesReceivedCounter((prevCounter) => ({
          ...prevCounter,
          [topic]: (prevCounter[topic] || 0) + 1,
        }));
      } else {
        console.log("Charging_DatCharging_Data", topic);

        mqttUpdatedProducts = await applyMqttLogic(topic, message);
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(DashboardUpdateData(mqttUpdatedProducts));
      }

      const updatedMessage = topic.includes("Charging_Data")
        ? "Charging_Data"
        : message.toString();
      setCheckmqttMessagesReceived((prevMessages) => ({
        ...prevMessages,
        [topic]: updatedMessage,
      }));
    } catch (error) {
      console.error("Error parsing received message:", error);
    }
  };

  useEffect(() => {
    if (mqttClient) {
      mqttClient.removeAllListeners("message");
      mqttClient.on("message", handleMessageLogic);

      return () => {
        mqttClient.removeAllListeners("message");
      };
    }
  }, [mqttClient, AllPidDataWithMessages, CheckmqttMessagesReceivedCounter]);

  useEffect(() => {
    if (!Array.isArray(pidsData)) {
      console.error("pidsData is not an array");
      return;
    }
    const applyApiLogic = (apiProducts) => {
      // Apply your logic here (for example, filtering or modifying products)
      return apiProducts.map((product) => ({
        pid: product?.pid,
        remark: "",
        status: "",
        AppUsername: "No User",
        chargername: product?.ChargerName,
      }));
    };

    // Apply API logic only if pidsData is an array
    const apiUpdatedProducts = applyApiLogic(pidsData);
    console.log("apiUpdatedProducts", apiUpdatedProducts);

    // Dispatch the updated products to the Redux store
    dispatch(DashboardUpdateData(apiUpdatedProducts));
  }, [pidsData, dispatch]);

  const handleSearch = () => {
    console.log(search);
    const user = data.find((element) => element.id === search);
    if (user === undefined) {
      console.log(user, "no data found");
    } else {
      console.log(user);
      setAlldata([user]);
    }
  };

  const finction = () => {
    console.log("kjevnve");
    // dispatch(createPaymentIntent(data))
  };

  const handleSubmit = async () => {
    // Do something with the submitted PID
    console.log("Submitted PID:", pidInput);
    await dispatch(postPid({ userId: uid, pid: pidInput }));
    setPidInput("");
  };
  const columns = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "Status",
      accessor: "chargerstatus",
    },
    {
      header: "Used by",
      accessor: "usedby",
    },
    {
      header: "User Address",
      accessor: "useraddress",
    },
    {
      header: "Vehicle",
      accessor: "vehicle",
    },
    {
      header: "Remarks",
      accessor: "remark",
    },
    {
      header: "Action",
      accessor: "action",
    },
  ];

  let count = 1;
  const data = AllPidDataWithMessages.map((user) => {
    return {
      serialno: count++,
      chargername: user?.chargername,
      chargerid: user?.pid,
      chargerstatus: user?.status || (
        <div className="flex justify-center">
          <Rings
            visible={true}
            height="35"
            width="35"
            color="#4fa94d"
            ariaLabel="rings-loading"
          />
        </div>
      ),
      // usedby: user.Usedbymessage, // Assigning the array of matched values to 'usedby'
      usedby: user?.AppUsername, // Assigning the array of matched values to 'usedby'
      useraddress: user?.Address || "Not Mentioned",
      vehicle: user?.vehicle || "Not Mentioned",
      remark: user?.remark || "No Remarks",
    };
  });

  const handleClick4 = () => {
    navigate("/addcharger");
  };
  console.log("data", data);
  // const data = 89;
  // useEffect(() => {
  //   console.log(pidsData?.length, "kkkkkkkkkk");
  //   if (pidsData?.length === 0) {

  //     setShowModal(true);
  //     console.log("harsh sethshowmdal in usedeffect dashboard in if ");
  //   }
  //   // else{
  //   //   setShowModal(false)
  //   // }
  // }, [pidsData]);

  console.log(showModal, "lllllooo");

  const clickaddcharge = () => {
    console.log("click hua add charger in revenuemagages");
    // navigate("/addcharger");
  };
  useEffect(() => {
    if (!Array.isArray(AllPidDataWithMessages)) {
      console.error("AllPidDataWithMessages is not an array");
      return;
    }
    const filterData = AllPidDataWithMessages.filter(
      (e) =>
        e && // Check if e exists
        e.status && // Check if e.status exists
        (e.status.toLowerCase() === "charging started" ||
          e.status.toLowerCase() === "charger connected" ||
          e.status.toLowerCase() === "charging completed")
    );
    console.log("filterdatafilterdata", AllPidDataWithMessages);
    setShowActiveDevices(filterData.length);
  }, [AllPidDataWithMessages]);

  useEffect(() => {
    const data = [{ serialno: 1 }, { serialno: 2 }, { serialno: 3 }];
    setDummyDataforTable(data);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar collapsed={collapsed} rtl={rtl} />

      <main className="w-full py-8 px-4 lg:ml-20 mt-12 overflow-hidden">
        {/* <Chat /> */}
        <div className="w-full mb-8 text-lg md:text-xl lg:text-3xl font-semibold text-[#605C5C] capitalize ">
          Dashboard
        </div>
        <div className="">
          <div className="flex justify-center md:justify-between flex-wrap gap-1">
            <div className="mb-4">
              <DashBoardCard
                title={pidsData.length}
                disc="Total Devices"
                seticon={"PowerIcon"}
              />
            </div>
            <div className="mb-4">
              <DashBoardCard
                title={ShowActiveDevices}
                disc="Active Devices"
                seticon={"PowerIcon"}
              />
            </div>
            <div className="mb-4">
              <DashBoardCard
                title={`${ShowTotalEnergy} Wh`}
                disc="Total Energy"
                seticon={"BoltIcon"}
                SubDis={"Watt Hour"}
              />
            </div>
            <div className="mb-4">
              <DashBoardCard
                title={ShowTotalRevenue}
                disc="Total Revenue"
                seticon={"CurrencyRupeeIcon"}
              />
            </div>
          </div>
        </div>

        {showAddChargerModal && (
          <Modal
            open={showModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} // Center the modal vertically and horizontally
          >
            <div
              className="modal-content"
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                maxWidth: "400px",
              }}
            >
              <h2 className="modal-title" style={{ marginBottom: "20px" }}>
                Add Charger
              </h2>
              <div className="modal-body">
                <div className="my-3">
                  <div className="flex justify-between mt-4">
                    <div>
                      <Button
                        onClick={closeModal}
                        variant="contained"
                        size="large"
                        color="default"
                        style={{
                          marginRight: "5px",
                          backgroundColor: "#118615",
                          borderRadius: "15px",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={handleClick4}
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{
                          backgroundColor: "#118615",
                          borderRadius: "15px",
                        }}
                      >
                        ADD CHARGER
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {data && data.length > 0 ? (
          <Table
            greenClicked={clickaddcharge}
            columns={columns}
            data={data}
            pageSize={10}
            greenButtonText={"Export All"}
          />
        ) : (
          <>
            <Table
              greenClicked={clickaddcharge}
              columns={columns}
              data={DummyDataforTable}
              pageSize={10}
            />
          </>
        )}

        <div className="w-full overflow-hidden">
          <div className=" font-semibold text-2xl">Cumulative Usage</div>
          <div className="flex justify-center">
            <div className="w-full lg:w-5/6  mt-2 ">
              <BarChart
                xLabelData={xlabel}
                ylabelData={ylabel}
                ylabelName={"Energy"}
                xlabelName={"Date"}
              />
            </div>
          </div>
          {/* <div className="w-full lg:w-1/2 ">
        <PieChart />
      </div> */}
        </div>
      </main>
    </div>
  );
};

export default Dashboard;

{
  /* <RefreshButton onClick={handleRefreshClick} /> */
}
