export function RevenueXlabel(data) {
    const uniqueDates = new Set();
  
    data.forEach(entry => {
        uniqueDates.add(entry.Date);
    });
  
    let uniqueDatesArray = [...uniqueDates];
  
    uniqueDatesArray.sort((a, b) => {
      const dateA = new Date(
        parseInt(a.split("/")[2]), // Year
        parseInt(a.split("/")[1]) - 1, // Month (zero-based index)
        parseInt(a.split("/")[0]) // Day
      );
      const dateB = new Date(
        parseInt(b.split("/")[2]),
        parseInt(b.split("/")[1]) - 1,
        parseInt(b.split("/")[0])
      );
      return dateA - dateB;
    });
  
    return uniqueDatesArray;
  }