// slice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Toast } from "react-bootstrap";
// const ApiUrl = "http://165.22.223.26:5000";
// const ApiUrl = "http://backend.jouls.co.in:5000";
const ApiUrl = "https://backend.jouls.co.in"
// const ApiUrl = "http://localhost:5000";
// Define async thunk action creator for fetching catalog data
export const fetchCatalog = createAsyncThunk(
  "catalog/fetchCatalog",
  async () => {
    try {
      const response = await axios.get(
        "https://tssapis.devcorps.in/client/catalog/catalog"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user",
  async (formData) => {
    try {
      const response = await axios.get(
        `${ApiUrl}/userregister/email/${formData}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const postPid = createAsyncThunk(
  "user/postPid",
  async ({ userId, pid }) => {
    try {
      const response = await axios.put(
        `${ApiUrl}/user/addpid/users/${userId}`,
        { pid }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addpid = createAsyncThunk(
  "user/addpid",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${ApiUrl}/user/addpid/create`, {
      // const response = await fetch(`http:localhost:5000/user/addpid/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create user");
      }

      const data = await response.json();
      console.log("data", data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllPids = createAsyncThunk(
  "getAllPids",
  async ({uid,populateBankDetails,populateChargerHistoryData,populatePidsDetails}, { rejectWithValue }) => {
    try {
      // const response = await axios.get(${tssurl}/product, {
        // const response = await axios.get(`${ApiUrl}/user/UserPanelRoutes/${uid}?populateBankDetails=${populateBankDetails}&populateChargerHistoryData=${populateChargerHistoryData}&populatePidsDetails=${populatePidsDetails}`, {

      const response = await axios.get(`${ApiUrl}/user/addpid/${uid}`, {
        headers: {
          authorization: `${localStorage.getItem("jwt")}`,
          "API-Key": "90bd6f5b-033f-42e7-8e92-2a443dfa42f8",
        },
      });
      console.log("response in productPerformance", response.data);
      return response.data;
    } catch (error) {
      console.log("Not submitting data");
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async ({uid,populateBankDetails,populateChargerHistoryData,populatePidsDetails}, { rejectWithValue }) => {
    try {
      // const response = await axios.get(${tssurl}/product, {
      const response = await axios.get(`${ApiUrl}/user/UserPanelRoutes/${uid}?populateBankDetails=${populateBankDetails}&populateChargerHistoryData=${populateChargerHistoryData}&populatePidsDetails=${populatePidsDetails}`, {
        headers: {
          authorization: `${localStorage.getItem("jwt")}`,
          "API-Key": "90bd6f5b-033f-42e7-8e92-2a443dfa42f8",
        },
      });
      console.log("response in productPerformance inget user", response);

      if(populateChargerHistoryData && populatePidsDetails){
        return response.data;
      }
      if(populateBankDetails){
        return response.data;
      }
      if(populateChargerHistoryData){
        return response.data;
      }
      if(populatePidsDetails){
        return response.data;
      }
    } catch (error) {
      console.log("Not submitting data");
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

// get all charger history
export const getAllChargerHistory = createAsyncThunk(
  "getAllChargerHistory",
  async (uid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiUrl}/user/chargerhistory/getall/${uid}`, {
        headers: {
          authorization: `${localStorage.getItem("jwt")}`,
          "API-Key": "90bd6f5b-033f-42e7-8e92-2a443dfa42f8",
        },
      });
      console.log("response in productPerformance", response);
      return response.data.data;
    } catch (error) { 
      console.log("Not submitting data");
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
)

export const getAllAppUserScanPid = createAsyncThunk(
  "getAllAppUserScanPid",
  async (uid, { rejectWithValue }) => {
    try {
      // const response = await axios.get(${tssurl}/product, {
      const response = await axios.get(`${ApiUrl}/client/scan/getall`, {
        headers: {
          authorization: `${localStorage.getItem("jwt")}`,
          "API-Key": "90bd6f5b-033f-42e7-8e92-2a443dfa42f8",
        },
      });
      console.log("response in productPerformance", response.data.data);
      return response.data.data;
    } catch (error) {
      console.log("Not submitting data");
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPaymentIntent = createAsyncThunk(
  "payments/createPaymentIntent",
  async (amount) => {
    console.log(amount, "amount");
    try {
      const response = await axios.post(
        `${ApiUrl}/client/create-payment-intent`,
        { amount: amount }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${ApiUrl}/userregister/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create user");
      }

      const data = await response.json();
      const data1 = JSON.stringify(data)
      console.log(data,"kkkkkkkkk");
      localStorage.setItem("user", data1);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${ApiUrl}/userregister/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        Toast.error('Failed to login');
        throw new Error("Failed to login");
      }

      const data = await response.json();
      const data1 = JSON.stringify(data)
      console.log(response,"harshstoredstored");
      if(data1){
        localStorage.setItem("user", data1);
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// change chargingcost per hour  maintaines charge
export const UpdateCost = createAsyncThunk(
  "user/UpdateCost",
  async ({ formData, uid }, { rejectWithValue }) => {
    try {
    console.log("formdata",formData);
      const response = await fetch(`${ApiUrl}/user/addpid/updatecost/${uid}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to login");
      }

      const data = await response.json();
      console.log("data",data);
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const editChargerName = createAsyncThunk(
  "user/editChargerName",
  async ({ pid, newChargerName }) => {
    try {
      const response = await axios.put(
        `${ApiUrl}/user/addpid/updated/${pid}`,
        { ChargerName: newChargerName }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const DeletePid = createAsyncThunk(
  "user/DeletePid",
  async ({ pid }) => {
    try {
      const response = await axios.delete(`${ApiUrl}/user/addpid/deleted/${pid}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);


const appSlice = createSlice({
  name: "app",
  initialState: {
    user: [],
    status: "idle",
    error: null,
    sample: [],
    getAllPids: [],
    getUserDetails: [],
    getAllChargerHistory:[],
    getAllAppUserScanPid: [],
    allMqttMessagesPids: [],
    allDashboardPidsMessages: [],
    allMqttChargingData:[],
    ylabelData:[],
    xlabeldata:[],
    revenueXlabel:[],
    revenueYlabel:[]
  },
  reducers: {
    dummy: (state) => state,
    updateData: (state, action) => {
      state.allMqttMessagesPids = action.payload;
    },
    DashboardUpdateData: (state, action) => {
      state.allDashboardPidsMessages = action.payload;
    },
    ChargingUpdateData: (state, action) => {
      state.allMqttChargingData.push(action.payload);
    },
    YlabelUpdateData: (state, action) => {
      state.ylabelData = action.payload;
    },
    XlabelUpdateData: (state, action) => {
      state.xlabeldata = action.payload;
    },
    RevenueXlabelUpdateData: (state, action) => {
      state.revenueXlabel = action.payload;
    },
    RevenueYlabelUpdateData: (state, action) => {
      state.revenueYlabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCatalog.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sample = action.payload.catalogItems;
      })
      .addCase(fetchCatalog.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(createPaymentIntent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sample = action.payload.catalogItems;
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // .addCase(createUser.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(createUser.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.sample = action.payload;
      // })
      // .addCase(createUser.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.error.message;
      // })
      // get all api scan pids
      .addCase(getAllAppUserScanPid.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAppUserScanPid.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllAppUserScanPid = action.payload;
      })
      .addCase(getAllAppUserScanPid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message;
      })




      .addCase(postPid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle fulfilled action
      .addCase(postPid.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user = action.payload; // Update user state with the response data
      })
      // Handle rejected action
      .addCase(postPid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Update error state with the error message
      })
      

      // creatuser
      .addCase(createUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sample = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // login user

      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // get all pids
      .addCase(getAllPids.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllPids.fulfilled, (state, action) => {
        console.log(action.payload,"actionpay")
        state.status = "succeeded";
        state.getAllPids = action.payload;
      })
      .addCase(getAllPids.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // get userdetails
      .addCase(getUserDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        console.log(action.payload,"actionpay")
        state.status = "succeeded";
        if(action.payload.pids){
          state.getAllPids = action.payload.pids;
        }
        if(action.payload.ChargerHistory){
          state.getAllChargerHistory = action.payload.ChargerHistory;
        }
        state.getUserDetails = action.payload;
        
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // edit chargername
      .addCase(editChargerName.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editChargerName.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.getAllPids = action.payload;
      })
      .addCase(editChargerName.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(DeletePid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DeletePid.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.getAllPids = action.payload;
      })
      .addCase(DeletePid.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // all chargerhistory 

      .addCase(getAllChargerHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllChargerHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.getAllChargerHistory = action.payload;
      })
      .addCase(getAllChargerHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
  
  // extraReducers: (builder) => {

  //   builder
  //     .addCase(createUser.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(createUser.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.sample = action.payload
  //       ;
  //     })
  //     .addCase(createUser.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.error.message;
  //     })
  // },
  // extraReducers: (builder) => {

  //   builder
  //     .addCase(loginUser.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(loginUser.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.sample = action.payload
  //       ;
  //     })
  //     .addCase(loginUser.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.error.message;
  //     })
  // },
});
export const { dummy, updateData, DashboardUpdateData,ChargingUpdateData ,YlabelUpdateData,XlabelUpdateData,RevenueXlabelUpdateData,RevenueYlabelUpdateData} = appSlice.actions;

export default appSlice.reducer;
