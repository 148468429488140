import React from "react";
import "./PlusContainer.css"; // Import a CSS file for styling
import Circle from "../Circle";

const PlusContainer = ({ handleShow }) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-xl m-10 h-96 bg-white drop-shadow-2xl">
      <div className="plus-icons">
        {/* <div className="plus-icon" onClick={handleShow}>&#43;</div> */}
        <Circle handleShow={handleShow} />
        {/* Add more plus icons as needed */}
      </div>
      <p className="container-text">Click Here to Add your chargers</p>
    </div>
  );
};

export default PlusContainer;
