import React from "react";
import { Card } from "react-bootstrap"; // Import Card component from react-bootstrap
import Detailscardrevnue from "./Detailscardrevnue";
import { useSelector } from "react-redux";

const ContainerWithBorder = ({
  ShowTotalRevenue,
  ShowTotalEnergy,
  ShowTotalUtilization,
  ShowIdleDevices,
  ShowActiveDevices,
}) => {
  const pidsData = useSelector((state) => state.Store.getAllPids);
  return (
    <div className="w-full h-full">
      {/* First Row */}
      <div className="h-full flex justify-evenly flex-wrap items-center">
        <Detailscardrevnue title={"Total Revenue"} data={ShowTotalRevenue} />
        <Detailscardrevnue title={"Total Devices"} data={pidsData.length} />
        <Detailscardrevnue title={"Idle Devices"} data={ShowIdleDevices} />
        <Detailscardrevnue title={"Active Devices"} data={ShowActiveDevices} />
        <Detailscardrevnue
          title={"Total Utilization"}
          data={ShowTotalUtilization}
        />
        <Detailscardrevnue title={"Total Energy"} data={ShowTotalEnergy} />
      </div>
    </div>
  );
};

export default ContainerWithBorder;

// import React from "react";
// import { Card } from "react-bootstrap"; // Import Card component from react-bootstrap
// import Detailscardrevnue from "./Detailscardrevnue";

// const ContainerWithBorder = () => {
//   return (
//     <div className="w-full flex flex-col justify-around p-4">
//       {/* First Row */}
//       <div className="flex justify-between">
//         <Detailscardrevnue title={"Total Devices"} />
//         <Detailscardrevnue />
//         <Detailscardrevnue />
//         {/* <div className="rounded-md border border-[gray-600] hover:scale-125 duration-300">
//           <div
//             className="w-44 h-28 p-2"
//             style={{
//               padding: "5px",
//               // marginTop: "20px",
//               position: "relative",
//             }}
//           >
//             Total Devices
//             <h2 className="m-2">22</h2>
//             <div
//               style={{
//                 content: "",
//                 position: "absolute",
//                 width: "80px",
//                 height: "80px",
//                 backgroundColor: "#CEDEF8",
//                 borderRadius: "100% 0 10% 0",
//                 bottom: "40px",
//                 right: "40px",
//                 transform: "translate(50%, 50%)",
//               }}
//             ></div>
//           </div>
//         </div> */}
//         {/* <div style={{ width: "30%", padding: "10px" }}>
//           <Card
//             style={{
//               padding: "5px",
//               marginBottom: "20px",
//               position: "relative",
//             }}
//           >
//             Idle Devices
//             <Card.Body style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
//               12
//             </Card.Body>
//             <div
//               style={{
//                 content: "",
//                 position: "absolute",
//                 width: "80px",
//                 height: "80px",
//                 backgroundColor: "#CEDEF8",
//                 borderRadius: "100% 0 10% 0",
//                 bottom: "40px",
//                 right: "40px",
//                 transform: "translate(50%, 50%)",
//               }}
//             ></div>
//           </Card>
//         </div>
//         <div style={{ width: "30%", padding: "10px" }}>
//           <Card
//             style={{
//               padding: "5px",
//               marginBottom: "20px",
//               position: "relative",
//             }}
//           >
//             Active Devices
//             <Card.Body style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
//               10
//             </Card.Body>
//             <div
//               style={{
//                 content: "",
//                 position: "absolute",
//                 width: "80px",
//                 height: "80px",
//                 backgroundColor: "#CEDEF8",
//                 borderRadius: "100% 0 10% 0",
//                 bottom: "40px",
//                 right: "40px",
//                 transform: "translate(50%, 50%)",
//               }}
//             ></div>
//           </Card>
//         </div> */}
//       </div>
//       {/* Second Row */}
//       <div className="flex justify-between">
//         <Detailscardrevnue title={"Total Devices"} />
//         <Detailscardrevnue />
//         <Detailscardrevnue />
//         {/* <div style={{ width: "30%", padding: "10px" }}>
//           <Card
//             style={{
//               padding: "5px",
//               marginBottom: "20px",
//               position: "relative",
//             }}
//           >
//             Total Energy
//             <Card.Body style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
//               44
//             </Card.Body>
//             <div
//               style={{
//                 content: "",
//                 position: "absolute",
//                 width: "80px",
//                 height: "80px",
//                 backgroundColor: "#CEDEF8",
//                 borderRadius: "100% 0 10% 0",
//                 bottom: "40px",
//                 right: "40px",
//                 transform: "translate(50%, 50%)",
//               }}
//             ></div>
//           </Card>
//         </div>
//         <div style={{ width: "30%", padding: "10px" }}>
//           <Card
//             style={{
//               padding: "5px",
//               marginBottom: "20px",
//               position: "relative",
//             }}
//           >
//             Total Utilization
//             <Card.Body style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
//               15
//             </Card.Body>
//             <div
//               style={{
//                 content: "",
//                 position: "absolute",
//                 width: "80px",
//                 height: "80px",
//                 backgroundColor: "#CEDEF8",
//                 borderRadius: "100% 0 10% 0",
//                 bottom: "40px",
//                 right: "40px",
//                 transform: "translate(50%, 50%)",
//               }}
//             ></div>
//           </Card>
//         </div>
//         <div style={{ width: "30%", padding: "10px" }}>
//           <Card
//             style={{
//               padding: "5px",
//               marginBottom: "20px",
//               position: "relative",
//             }}
//           >
//             Total Revenue
//             <Card.Body style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
//               60k
//             </Card.Body>
//             <div
//               style={{
//                 content: "",
//                 position: "absolute",
//                 width: "80px",
//                 height: "80px",
//                 backgroundColor: "#CEDEF8",
//                 borderRadius: "100% 0 10% 0",
//                 bottom: "40px",
//                 right: "40px",
//                 transform: "translate(50%, 50%)",
//               }}
//             ></div>
//           </Card>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default ContainerWithBorder;
