import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";

const LineChart = ({ xLabelData,ylabelData ,xlabelName,ylabelName}) => {
  console.log("xLabelData",xLabelData );
  const chartRef = useRef(null);
  const chargingData = useSelector((state) => state.Store.allMqttChargingData);
  const energyData = chargingData.map((entry) => entry.energy);
  const timeData = chargingData.map((entry) => entry.time);
  console.log(energyData, "ssdsd");
  console.log(timeData);

  useEffect(() => {
    let myChart = null;

    const ctx = chartRef.current.getContext("2d");
    myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: xLabelData || [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Energy",
            data:ylabelData|| energyData || [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: xlabelName|| "Date",
            },
          },
          y: {
            title: {
              display: true,
              text: ylabelName||"Energy",
            },
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      if (myChart) {
        myChart.destroy(); // Cleanup on component unmount
      }
    };
  }, [ylabelData, xLabelData]);

  return (
    <div className="w-full ">
      <canvas ref={chartRef} className="min-h-52" />
    </div>
  );
};

export default LineChart;
