import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';
import { generateTimesArray } from '../../../genric/XlabelforHistory/xlabelforHistory';
import { useDispatch, useSelector } from 'react-redux';
import { ChargingUpdateData, XlabelUpdateData, YlabelUpdateData } from '../../../Redux/counterSlice';

let stopxlabelData = true;

function MqttYlabel() {
    // const [xlabelData,setxlabelData]=useState([])
    const dispatch=useDispatch();
    // const [stopxlabelData,setStopxLabelData]=useState(true)
    const chargingData=useSelector((state)=> state.Store.allMqttChargingData);
    const YlabelchargingData=useSelector((state)=> state.Store.ylabelData); 
    const xlabelData=useSelector((state)=> state.Store.xlabeldata); 
    console.log("chargindata",chargingData,YlabelchargingData,xlabelData);
    const [powerDataArray, setPowerDataArray] = useState([]);

    useEffect(() => {
        const client = mqtt.connect('wss://mqtt.jouls.co.in/mqtt');

        client.on('connect', () => {
            console.log('Connected to MQTT broker');
            client.subscribe('charging_data');
        });

        client.on('message', async(topic, message) => {
            console.log(topic,"topic");
            if(topic=="charging_data"){
                if(stopxlabelData){
                    console.log("stopxlabeldata",stopxlabelData);
                    const xlabelData1=generateTimesArray();
                    dispatch(XlabelUpdateData(xlabelData1))
                    stopxlabelData=false
                    // setStopxLabelData(false)
                }
            }
            const newMessage = JSON.parse(message.toString());
            const currentTime = new Date();
            const hour = currentTime.getHours().toString().padStart(2, '0'); // Add leading zero if needed
            const minute = currentTime.getMinutes().toString().padStart(2, '0'); // Add leading zero if needed
            newMessage.time = `${hour}:${minute}`;
            dispatch(ChargingUpdateData(newMessage));
        });

        return () => {
            client.end();
        };
    }, []);

    useEffect(() => {
        const averages = [];    
        console.log(xlabelData,"xlabelDatainylabedata");
        xlabelData.forEach((xlabel, index) => {
            // Check if it's the first xlabel, if so, push 0 and return
            if (index === 0) {
                const filteredData = chargingData.filter(data => data.time==xlabel);
                console.log("filteredData",filteredData);
                if (filteredData.length > 0) {
                    const sum = filteredData.reduce((acc, curr) => acc + parseInt(curr.Output_Power), 0);
                    console.log("sum",sum);
                    const average = sum / filteredData.length;
                    console.log("average",average);
                    averages.push({ xlabel, average });
                    // averages.push(average);
                }
                else {
                    averages.push({ xlabel, average:0 });
                    // averages.push(0); // If no data for the time range, push 0 as average
                }
                return;
            }
        
            const prevXlabel = xlabelData[index - 1];
            const filteredData = chargingData.filter(data => data.time > prevXlabel && data.time <= xlabel);
            if (filteredData.length > 0) {
                const sum = filteredData.reduce((acc, curr) => acc + parseInt(curr.Output_Power), 0);
                const average = sum / filteredData.length;
                averages.push({ xlabel, average });
                // averages.push(average);
            } else {
                averages.push({ xlabel, average:0 });
                // averages.push(0); // If no data for the time range, push 0 as average
            }
        });
        console.log("newmessgagesaverage",averages);
        dispatch(YlabelUpdateData(averages));
        setPowerDataArray(averages); // Set powerDataArray after calculating newMessages
    }, [chargingData,xlabelData]); // Only run this effect when chargingData changes

    // const calculateAveragePower = (startTime, endTime) => {
    //     const relevantMessages = chargingData.filter(message => message.time >= startTime && message.time <= endTime);
    //     console.log("relevantMessages",relevantMessages);
    //     if (relevantMessages.length === 0) return 0;
    //     const totalPower = relevantMessages.reduce((sum, message) => sum + message.Output_Power, 0);
    //     return totalPower / relevantMessages.length;
    // };
  
     // for (let i = 0; i < xlabelData.length; i++) {
        //     const time = xlabelData[i];
        //     const prevTime = i > 0 ? xlabelData[i - 1] : xlabelData[i];
        //     console.log("chargingdata",chargingData);
        //     // Calculate average for each time interval
        //     const averagePower = calculateAveragePower(prevTime, time);
        //     newMessages.push({ time, averagePower });
        //     // newMessages.push(averagePower);
        // }
    return (
      <></>
    )
}

export default MqttYlabel;
