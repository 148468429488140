import React, { useState ,useEffect, useRef} from "react";
import axios from "axios";
import io from "socket.io-client"
;
// import { FaPhone, FaWhatsapp, FaEnvelope } from 'react-icons/fa'; // Importing Font Awesome icons
import "./PlusContainer.css"; // Import a CSS file for styling
// import Circle from '../Circle';
import SupportChatModal from "./SupportChatModal.js"; // Import your modal component
import { green } from "@mui/material/colors";
// const url = "http://165.22.223.26:5000";
const url = "https://backend.jouls.co.in";
const url1 = "http://165.22.223.26:3005"

const PlusContainer = ({ title, choose }) => {
  const [ formData, setFormData] = useState({
    option: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const [chats, setChats] = useState([]);
  const [socket, setSocket] = useState(null);

  const handleOptionChange = (event) => {
    const selectedOptionValue = event.target.value;
    const selectedOptionText =
      event.target.options[event.target.selectedIndex].text;
    setFormData({ ...formData, option: selectedOptionText });
  };

  const handleMessageChange = (event) => {
    setFormData({ ...formData, message: event.target.value });
  };

  const handleCallUs = () => {
    window.open("tel:+916377650430", "_self");
  };
  const handleWhatsapp = () => {
    window.open("https://wa.me/916377650430", "_blank");
  };

  const handleEmail = () => {
    window.open("mailto:amangoyal6377@gmail.com", "_blank");
  };

  const stored = localStorage.getItem("user");
  const storedUsername = JSON.parse(stored);
  const userId = storedUsername?.userData?.uid;

  const raw = {
    tid: userId,
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log("Selected Option:", formData.option);
  //   console.log("Message:", formData.message);
  //   // Add your form submission logic here
  //   setFormData({
  //     option: "",
  //     message: "",
  //   });
  //   setShowModal(true); // Open the modal
  // };
  const chatScrollerRef = useRef(null);
  useEffect(() => {
    // Scroll chatbox to the bottom when component mounts or chats change
    scrollToBottom();
  }, [showModal, chats]);

  const scrollToBottom = () => {
    // Scroll to the bottom of the chatbox
    if (chatScrollerRef.current) {
      chatScrollerRef.current.scrollTop = chatScrollerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    try {
      const socketinit = io.connect(url1);
      // const socketinit = io.connect("https://ws_tss.devcorps.in/");
      setSocket(socketinit);

      socketinit.on("help_desk_receive", (message) => {
        console.log("dskjbdvsjdvhbsdjvbdvjdskbvsjkb", message);

        // setChats((prevMessages) => [...prevMessages, message]);
        handleSubmit();
      });
    } catch (error) {
      console.log("Error Sockect io in ", error);
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission default behavior
  
    try {
      const response = await axios({
        method: "post",
        url: `${url}/client/support/getTicketHandler`,
        data: raw,
        headers: {
          "Content-Type": "application/json",
          "API-Key": "90bd6f5b-033f-42e7-8e92-2a443dfa42f8",
        },
      });
      
      setChats(response?.data.data?.[0]?.msgs);
      setFormData({
        option: "",
        message: "",
      });
      setShowModal(true); // Open the modal
      if (response.data.msgs) {
        let scroller = document.getElementById("chat-scroller");
        setTimeout(() => {
          scroller.scrollTo(0, scroller.scrollHeight);
        }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div className="xl:flex justify-center gap-10">
      <div className="p-5 m-1 xl:w-2/5  bg-white shadow-lg rounded-xl ">
        <p className="text-center " style={{ fontSize: "1.2rem" }}>
          <b>Contact Info</b>
        </p>
        <div className="contact-info">
          <div className="container credential-box">
            <button className="btn " onClick={handleCallUs}>
              <b style={{ fontSize: "1.2rem" }}>Call Us</b>
            </button>
          </div>
          <div className="container credential-box">
            {/* <strong>:</strong> */}
            {/* <a href="https://wa.me/916377650430">Whatsapp</a> */}
            <button className="btn " onClick={handleWhatsapp}>
              <b style={{ fontSize: "1.2rem" }}>Whatsapp</b>
            </button>
          </div>
          <div className="container credential-box">
            {/* <strong>Email:</strong> */}
            {/* <a href="mailto:amangoyal6377@gmail.com">Email</a> */}
            <button className="btn" onClick={handleEmail}>
              <b style={{ fontSize: "1.2rem" }}>Email</b>
            </button>
          </div>
          <div className="row">
            {/* <strong>Offices:</strong> */}
            <div className="text-xl text-center font-bold mb-4">Offices</div>
            <div className="office-details">
              <div className="md:flex  gap-8 text-md ">
                {/* sm:flex-wrap lg:flex-nowrap */}
                <div className="mb-4">
                  <div className="border-4 border-[#118615] rounded-xl p-2 h-full">
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=Flat+No:+177,+Second+Floor,+Block+C,+South+City+II,+Sector+49,+Gurugram,+Haryana,+India+(122018)"
                      style={{
                        // fontSize: "1rem",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      <span className="font-semibold">Reg. office : </span>
                      177, Second Floor, Block C, South City II, Sector 49,
                      Gurugram, Haryana, India(122018)
                    </a>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="border-4 border-[#118615] rounded-xl p-2">
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=Room+11,+Malviya+Innovation+and+Incubation+Center,+MNIT,+Jaipur,+Rajasthan+(302017)"
                      style={{
                        // fontSize: "1rem",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      <span className="font-semibold">Workshop : </span>
                      Malviya Innovation and Incubation Center, MNIT, Jaipur,
                      Rajasthan (302017)
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5 m-1 xl:w-2/5  bg-white shadow-lg rounded-xl ">
        <p className="text-center mt-3" style={{ fontSize: "1.2rem" }}>
          <b>Talk to Our Experts</b>
        </p>

        <form
         onSubmit={(event) => handleSubmit(event)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="form-group" style={{ marginBottom: "10px" }}>
            <label
              htmlFor="supportOptions"
              style={{ fontSize: "1.2rem", marginBottom: "5px" }}
            >
              {choose}
            </label>

            <select
              id="supportOptions"
              className="form-control"
              value={formData.option}
              onChange={handleOptionChange}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <option value="">Select your Objective</option>
              <option value="Charger Malfunction">Charger Malfunction</option>
              <option value="Wifi Connectivity Issue">
                Wifi Connectivity Issue
              </option>
              <option value="Charger Initialisation Failure">
                Charger Initialisation Failure
              </option>
              <option value="CMS Software Dsyfunction">
                CMS Software Dsyfunction
              </option>
              <option value="Charging System Inoperability">
                Charging System Inoperability
              </option>
              <option value="Other Inquiries">Other Inquiries</option>
            </select>
          </div>
          <div className="form-group" style={{ marginBottom: "10px" }}>
            <label
              htmlFor="message"
              style={{ fontSize: "1.2rem", marginBottom: "5px" }}
            >
              Message
            </label>
            <textarea
              id="message"
              className="form-control"
              value={formData.message}
              onChange={handleMessageChange}
              style={{ width: "100%", height: "150px", marginBottom: "10px" }}
            ></textarea>
            {/* Adjust the height value as needed */}
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            style={{ alignItems: "center" }}
          >
            Raise a Query
          </button>
        </form>
      </div>

      {/* <SupportChatModal
      chats1={chats}
        show={showModal}
        onClose={closeModal}
        initialObjective={formData.option}
        initialMessage={formData.message}
      /> */}
    </div>
  );
};

export default PlusContainer;
