import React, { useEffect, useState } from "react";

function DetailsCardRevenue(props) {
  const [time, setTime] = useState({ hours: "", minutes: "" });
  const { title, data } = props;

  console.log("data", typeof data);
  useEffect(() => {
    if (typeof data === "string" && data.includes("Hrs")) {
      const [hrsPart, minPart] = data.split(" Hrs ");
      setTime({ hours: hrsPart, minutes: minPart });
    } else {
      setTime({
        hours: "",
        minutes: typeof data === "string" ? data.split(" ")[0] : "",
      });
    }
  }, [data]);

  return (
    <div className="w-full md:w-44 h-28 rounded-md border border-[gray-600] hover:scale-110 duration-300 m-2">
      <div
        className="h-28 font-semibold p-2 "
        style={{
          // padding: "5px",
          position: "relative",
        }}
      >
        {title}

        {title == "Total Utilization" ? (
          time.hours ? (
            <div className="text-base lg:text-2xl font-semibold m-2">
              <p className="mb-0">{time.hours} hrs</p>
              <p>{time.minutes} </p>
            </div>
          ) : (
            <p className="mb-2 m-2">{time.minutes} min</p>
          )
        ) : (
          <p className="text-base lg:text-2xl font-semibold m-2 ">{data}</p>
        )}

        <div
          style={{
            content: "",
            position: "absolute",
            width: "80px",
            height: "80px",
            backgroundColor: "#EBF5EB",
            // backgroundColor: "#CEDEF8",
            borderRadius: "100% 0 10% 0",
            bottom: "40px",
            right: "40px",
            transform: "translate(50%, 50%)",
            zIndex: -1,
          }}
        ></div>
      </div>
    </div>
  );
}

export default DetailsCardRevenue;
