import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Toast } from "react-bootstrap";
import axios from "axios";
import "./SupportChatModal1.css";

const SupportChatModal = ({ show, onClose, uid }) => {
  //     const stored = localStorage.getItem("user");
  //   const storedUsername = JSON.parse(stored);
  //   const uid = storedUsername?.userData?.uid;
  // console.log("hello",bankDetails1)
  const [selectedSettlementTerm, setSelectedSettlementTerm] = useState("");
  const [bankDetails, setBankDetails] = useState([]);
  const [formData, setFormData] = useState({
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    phoneNumber: "",
    settlementTerms: "",
  });
  const hardcodedBanks = [
    
        {
          "name": "State Bank of India",
          "image_url": "https://example.com/sbi_logo.png"
        },
        {
          "name": "HDFC Bank",
          "image_url": "https://example.com/hdfc_logo.png"
        },
        {
          "name": "ICICI Bank",
          "image_url": "https://example.com/icici_logo.png"
        },
        {
          "name": "Axis Bank",
          "image_url": "https://example.com/axis_logo.png"
        },
        {
          "name": "Punjab National Bank",
          "image_url": "https://example.com/pnb_logo.png"
        },
        {
          "name": "Canara Bank",
          "image_url": "https://example.com/canara_logo.png"
        },
        {
          "name": "Bank of Baroda",
          "image_url": "https://example.com/bob_logo.png"
        },
        {
          "name": "Union Bank of India",
          "image_url": "https://example.com/ubi_logo.png"
        },
        {
          "name": "IndusInd Bank",
          "image_url": "https://example.com/indusind_logo.png"
        },
        {
          "name": "IDBI Bank",
          "image_url": "https://example.com/idbi_logo.png"
        },
        {
          "name": "Kotak Mahindra Bank",
          "image_url": "https://example.com/kotak_logo.png"
        },
        {
          "name": "Yes Bank",
          "image_url": "https://example.com/yes_logo.png"
        },
        {
          "name": "Federal Bank",
          "image_url": "https://example.com/federal_logo.png"
        },
        {
          "name": "Standard Chartered Bank",
          "image_url": "https://example.com/standard_chartered_logo.png"
        },
        {
          "name": "HSBC Bank",
          "image_url": "https://example.com/hsbc_logo.png"
        },
        {
          "name": "Citibank",
          "image_url": "https://example.com/citibank_logo.png"
        },
        {
          "name": "IDFC First Bank",
          "image_url": "https://example.com/idfc_first_logo.png"
        },
        {
          "name": "RBL Bank",
          "image_url": "https://example.com/rbl_logo.png"
        },
        {
          "name": "Bandhan Bank",
          "image_url": "https://example.com/bandhan_logo.png"
        },
        {
          "name": "South Indian Bank",
          "image_url": "https://example.com/south_indian_logo.png"
        },
      
    
    // Add other hardcoded banks here
  ];
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedTerm, setSelectedTerm] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showfield, setshowfield] = useState(false);
  const [selectedBankDetail, setSelectedBankDetail] = useState(null);
  console.log("selected", selectedBankDetail);
  const populateBankDetails = true;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [bankDetailToDelete, setBankDetailToDelete] = useState(null);
  const handleBankSelect = (event) => {
    const selectedBankName = event.target.value;
    setSelectedBank(selectedBankName);
  
    // Find the selected bank details from the hardcoded banks array
    const selectedBankDetail = hardcodedBanks.find(bank => bank.name === selectedBankName);
  
    // If the selected bank details are found, update the formData state
    if (selectedBankDetail) {
      setFormData({
        ...formData,
        bankName: selectedBankDetail.name,
        // Add other bank details as needed
      });
    }
  };
  
  const handleDeleteConfirmation = (confirmed) => {
    if (confirmed) {
      deleteBankDetail(bankDetailToDelete);
    } else {
      setBankDetailToDelete(null);
      setShowDeleteConfirmation(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          // `http://165.22.223.26:5000/user/UserPanelRoutes/${uid}?populateBankDetails=${populateBankDetails}`
          `https://backend.jouls.co.in/user/UserPanelRoutes/${uid}?populateBankDetails=${populateBankDetails}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const userData = await response.json();
        console.log("Fetched user data:", userData); // Logging userData

        // Check if bank details exist
        if (userData.bankDetails) {
          setBankDetails(userData.bankDetails); // Set bank details to state
        } else {
          setBankDetails(null); // Reset bank details state
        }
      } catch (error) {
        console.log("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, [uid, populateBankDetails]);

  const handleEdit = async (bankDetail) => {
    console.log("baa", bankDetail);
    try {
      const response = await fetch(
        `https://backend.jouls.co.in/user/BankDetails/get/bank-details/${bankDetail}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setShowForm(false)
      setSelectedBankDetail(data);
      // setShowForm(!showForm);
      setShowEditModal(true);
    } catch (error) {
      console.log("Error fetching bank detail:", error);
    }
  };

  // const handleDelete = async (id) => {
  //   try {
  //     await axios.delete(
  //       `http://165.22.223.26:5000/user/BankDetails/bank-details/${id}`
  //     );
  //     const updatedBankDetails = bankDetails.filter(
  //       (detail) => detail._id !== id
  //     );
  //     setBankDetails(updatedBankDetails);
  //   } catch (error) {
  //     console.log("Error deleting bank detail:", error);
  //   }
  // };
  const handleDelete = async (id) => {
    setBankDetailToDelete(id);
    setShowDeleteConfirmation(true);
  };
  
  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSettlementButtonClick = (term) => {
    setSelectedSettlementTerm(term);
    console.log(term, "helllllllllllllllooo");
    setFormData({ ...formData, settlementTerms: term });
    // setSelectedTerm(term);
  };
  const handleOpenTextFieldModal = bankDetails.length <= 0;
  const handleOpenTextFieldModal1 = bankDetails.length > 0;
  console.log(handleOpenTextFieldModal,"abcdefgh");
  console.log(handleOpenTextFieldModal1,"abcdefghi");
  console.log(bankDetails.length,"bank");
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.post(
        `https://backend.jouls.co.in/user/BankDetails/bank-details/${uid}`,
        formData
      );
      onClose();
      window.location.reload();
    } catch (error) {
      console.log("Error submitting form:", error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      await axios.put(
        `https://backend.jouls.co.in/user/BankDetails/bank-details/${selectedBankDetail._id}`,
        selectedBankDetail
      );
      setShowEditModal(false);
      const updatedBankDetails = bankDetails.map((detail) => {
        if (detail._id === selectedBankDetail._id) {
          return { ...detail, ...formData };
        }
        return detail;
      });
      
      window.location.reload();
      setBankDetails(updatedBankDetails);
    } catch (error) {
      console.log("Error submitting edit form:", error);
    }
  };

  const handleEditFormDataChange = (event) => {
    const { name, value } = event.target;
    setSelectedBankDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const deleteBankDetail = async (id) => {
    try {
      await axios.delete(`https://backend.jouls.co.in/user/BankDetails/bank-details/${id}`);
      const updatedBankDetails = bankDetails.filter((detail) => detail._id !== id);
      setBankDetails(updatedBankDetails);
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.log("Error deleting bank detail:", error);
    }
  };
  const handleClose = () => {
    setFormData({
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      ifscCode: "",
      phoneNumber: "",
      settlementTerms: "",
    });
    setSelectedBankDetail(null);
    onClose();
  };
const data = ()=>{
  console.log("dskhvbbsjvhsh",!showfield);
  setShowForm(showForm)
  setshowfield(!showfield)
}
const data1 = ()=>{
  if(handleOpenTextFieldModal){
    onClose();
  }
  setFormData({
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    phoneNumber: "",
    settlementTerms: "",
  });
  setSelectedSettlementTerm(null)
  setSelectedBank("")
  setShowForm(showForm)
  setshowfield(!showfield)
}
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <div className="flex items-center justify-between  w-full">
            <Modal.Title>Bank Details</Modal.Title>
            
          
            {/* {(showForm || !showfield )&&(  */}
              {handleOpenTextFieldModal || showfield ? (
  ""
) : (
  <Button
    variant={"secondary"}
    onClick={data}
    style={{
      fontSize: "1.1rem",
      color: "white",
      textDecoration: "none",
      marginRight: "12px",
    }}
  >
    {(!showForm || !showfield) && "Add"}
  </Button>
)}

            
          </div>
        </Modal.Header>

        <Modal.Body>
         
        {(handleOpenTextFieldModal || showfield) && showForm && (
          <>
             {showForm && (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="bankSelect">
      <Form.Label>Select Bank</Form.Label>
      <Form.Control
        as="select"
        value={selectedBank}
        onChange={handleBankSelect}
      >
        <option value="">Select Bank Name</option>
        {/* Hardcoded bank options */}
        {hardcodedBanks.map((bank, index) => (
          <option key={index} value={bank.name}>
            {bank.name}
          </option>
        ))}
        {/* Bank options fetched from API */}
        {bankDetails.map((bank) => (
          <option key={bank.name} value={bank.name}>
            {bank.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  
                <Form.Group>
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    required={true}
                    placeholder="Enter account Number"
                    name="accountNumber"
                    value={formData?.accountNumber}
                    onChange={handleFormDataChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Account Holder Name</Form.Label>
                  <Form.Control
                    type="text"
                    required={true}
                    placeholder="Enter account holder name"
                    name="accountHolderName"
                    value={formData?.accountHolderName}
                    onChange={handleFormDataChange}
                  />
                </Form.Group>
                
                <Form.Group>
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    type="text"
                    required={true}
                    placeholder="Enter IFSC Code"
                    name="ifscCode"
                    value={formData?.ifscCode}
                    onChange={handleFormDataChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    required={true}
                    placeholder="Enter Phone Number"
                    name="phoneNumber"
                    value={formData?.phoneNumber}
                    onChange={handleFormDataChange}
                  />
                </Form.Group>
                {/* // Inside the edit modal Form.Group for "Select Settlement Terms" */}
                <Form.Group>
                  <Form.Label>Select Settlement Terms</Form.Label>
                  <div className="button-group">
          <Button
            onClick={() => handleSettlementButtonClick("T+7 Days")}
            variant={selectedSettlementTerm === "T+7 Days" ? "success" : "outline-primary"}
            className="settlement-button"
          >
            T+7 Days
          </Button>
          <Button
            onClick={() => handleSettlementButtonClick("T+15 Days")}
            variant={selectedSettlementTerm === "T+15 Days" ? "success" : "outline-primary"}
            className="settlement-button"
          >
            T+15 Days
          </Button>
          <Button
            onClick={() => handleSettlementButtonClick("T+30 Days")}
            variant={selectedSettlementTerm === "T+30 Days" ? "success" : "outline-primary"}
            className="settlement-button"
          >
            T+30 Days
          </Button>
        </div>
                </Form.Group>
  
                <Button variant="primary" type="submit" 
                 onClick={() => setShowForm(showForm)}>
                  Submit
                  
                </Button>
                <Button
                  variant="primary"
                  
                  style={{ marginLeft: "10px" }}
                  // onClick={() => setShowForm(!showForm)}
                  onClick={data1}
                  // onClose
                >
                  Cancel
                </Button>
              </Form>
            )}
            </>
          )}
      {!handleOpenTextFieldModal && (
        <>
            {bankDetails.map((bankDetail) => (
              <div className="mt-2 p-4 border-2 rounded-md" key={bankDetail._id}>
                <p>
                  <span className="font-bold"> Bank Name : </span>
                  {bankDetail.bankName}
                </p>
                <p>
                  <span className="font-bold"> Account Number : </span>
                  {bankDetail.accountNumber}
                </p>
                <p>
                  <span className="font-bold"> Account Holder Name : </span>
                  {bankDetail.accountHolderName}
                </p>
               
                <p>
                  <span className="font-bold"> IFSC Code : </span>
                  {bankDetail.ifscCode}
                </p>
                <p>
                  <span className="font-bold"> Phone Number : </span>
                  {bankDetail.phoneNumber}
                </p>
                <p>
                  <span className="font-bold"> Settlement Terms : </span>{" "}
                  {bankDetail.settlementTerms}
                </p>
                <button
                  className="rounded-md py-2 bg-green hover:bg-blue-600"
                  onClick={() => handleEdit(bankDetail._id)}
                  
                >
                  Edit
                </button>
                <Toast
        show={showDeleteConfirmation}
        onClose={() => handleDeleteConfirmation(false)}
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          zIndex: 9999,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Delete Confirmation</strong>
        </Toast.Header>
        <Toast.Body>
          Are you sure you want to delete this bank detail?
          <div className="d-grid gap-2 mt-3">
            <Button variant="danger" onClick={() => handleDeleteConfirmation(true)}>
              Yes, Delete
            </Button>
            <Button variant="secondary" onClick={() => handleDeleteConfirmation(false)}>
              Cancel
            </Button>
          </div>
        </Toast.Body>
      </Toast>
                <button
                  className="rounded-md py-2 bg-green hover:bg-blue-600"
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleDelete(bankDetail._id)}
                >
                  Delete
                </button>
                {/* <hr /> */}
              </div>
            ))}
            </>
      )}
       
         
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        dialogClassName="modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Bank Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group>
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter bank name"
                name="bankName"
                value={selectedBankDetail?.bankName || ""}
                onChange={handleEditFormDataChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account number"
                name="accountNumber"
                value={selectedBankDetail?.accountNumber || ""}
                onChange={handleEditFormDataChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account holder name"
                name="accountHolderName"
                value={selectedBankDetail?.accountHolderName || ""}
                onChange={handleEditFormDataChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IFSC code"
                name="ifscCode"
                value={selectedBankDetail?.ifscCode || ""}
                onChange={handleEditFormDataChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                name="phoneNumber"
                value={selectedBankDetail?.phoneNumber || ""}
                onChange={handleEditFormDataChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Select Settlement Terms</Form.Label>
              <div className="button-group">
          <Button
            onClick={() => handleSettlementButtonClick("T+7 Days")}
            variant={selectedSettlementTerm === "T+7 Days" ? "success" : "outline-primary"}
            className="settlement-button"
          >
            T+7 Days
          </Button>
          <Button
            onClick={() => handleSettlementButtonClick("T+15 Days")}
            variant={selectedSettlementTerm === "T+15 Days" ? "success" : "outline-primary"}
            className="settlement-button"
          >
            T+15 Days
          </Button>
          <Button
            onClick={() => handleSettlementButtonClick("T+30 Days")}
            variant={selectedSettlementTerm === "T+30 Days" ? "success" : "outline-primary"}
            className="settlement-button"
          >
            T+30 Days
          </Button>
        </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <button
            className="rounded-md py-2 bg-[#118615] hover:bg-blue-600"
            style={{ marginLeft: "10px" }}
            onClick={handleEditSubmit}
          >
            Update
          </button>
          {/* <Button variant="primary" onClick={handleEditSubmit}>
            Update
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SupportChatModal;
