import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { Link } from "react-router-dom";
import TextInputs from "./TextInputs";
// import { SuccessToast, ErrorToast } from "../Toast/Toast";
import SuccessToast from "../Toast/SuccessToast";
import ErrorToast from "../Toast/ErrorToast";
import CircleComponent from "./CircleComponent";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { loginUser } from "../../Redux/counterSlice";
import { Opacity } from "@mui/icons-material";
// import { Toast } from "react-bootstrap";
import { Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
// import { tssurl } from "../../../UI/port";

const New_login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passVisibility, setpassVisibility] = useState("password");
  const [click, setclick] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  console.log(formData);
  // const loading = useSelector((state) => state.login.loading);
  // const error = useSelector((state) => state.login.error);

  const onInputChange = (fieldName, e) => {
    const value = e.target.value;
    console.log(value);
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleLoginClick = (e) => {
    e.preventDefault();

    if (formData.email === "" || formData.password === "") {
      // If email or password is empty, show error message
      setToastMessage("Email and password are required.");
      setShowErrorToast(true);
      return;
    }

    dispatch(loginUser(formData))
      .unwrap()
      .then((response) => {
        console.log("Login successful"); // Optional: Log success message
        setToastMessage("Login successful");
        setShowSuccessToast(true);
        setTimeout(() => {
          setShowSuccessToast(false);
          navigate("/dashboard"); // Navigate to dashboard after login
        }, 3000);
      })
      .catch((error) => {
        console.error("Login error:", error);
        setToastMessage("Login failed. Please try again.");
        setShowErrorToast(true);
        setTimeout(() => {
          setShowErrorToast(false);
        }, 3000);
      });
  };
  useEffect(() => {
    const loadGoogleSignInScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => initializeGoogleSignIn();
      document.body.appendChild(script);
    };

    const initializeGoogleSignIn = () => {
      window.onSignInSuccess = (response) => {
        console.log("Signed in successfully:", response);
        const idToken = response.getAuthResponse().id_token;
        axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
        // Perform any additional actions after successful sign-in, such as navigating to another page.
      };

      window.onSignInFailure = (error) => {
        console.error("Error signing in:", error);
        // Handle sign-in failure as needed.
      };

      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: {
          on_success: window.onSignInSuccess,
          on_failure: window.onSignInFailure,
        },
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        { theme: "outline", size: "large" }
      );
    };

    loadGoogleSignInScript();
  }, []);

  const handleGoogleLoginSuccess = (response) => {
    console.log("Google login success:", response);
    // Perform actions like saving user data or navigating to another page
    const token = response.tokenId;
    // Example: Send token to backend
    fetch("http://localhost:5200/client/google-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response from backend
        console.log("Backend response:", data);
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failure:", error);
    // Handle errors as needed
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "156987578910-u2mg62hrg7dk6d2deunerts475sr59mb.apps.googleusercontent.com",
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full h-screen ">
        <div className="flex w-2/3 border border-[#CACACA] rounded-xl overflow-hidden ">
          <div className="w-1/2 relative overflow-hidden bg-[#C1E0C2] xl:block hidden">
            <div className="absolute top-5 left-5 z-20 text-xl text-[#118615] font-bold">
              Welcome!
            </div>
            <div className="absolute flex flex-col justify-center items-center w-full h-full ">
              <div className="w-44 h-20 rounded-xl bg-white z-30">
                <img className="cover" src="/images/logoj.png" alt="logo" />
              </div>
              <h1 className="text-xs text-[#5E5E5E] mt-2">
                Energizing Greener Future
              </h1>
            </div>
            <SuccessToast
              message={toastMessage}
              open={showSuccessToast}
              onClose={() => setShowSuccessToast(false)}
            />
            <ErrorToast
              message={toastMessage}
              open={showErrorToast}
              onClose={() => setShowErrorToast(false)}
            />
            <div className="absolute top-0 bottom-0 flex -ml-28 items-center">
              <CircleComponent />
            </div>
            <div className="absolute h-full right-0 flex flex-col  justify-between -mr-24">
              <div className="-rotate-45 -mt-28 ">
                <CircleComponent mw={20} />
              </div>
              <div className="rotate-45 -mb-28">
                <CircleComponent />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center py-5 w-full xl:w-1/2   ">
            <div className="p-3  w-full md:w-2/4 xl:w-2/3 ">
              <div className="flex justify-center items-center text-lg text-[#6B6A6A] font-bold w-full">
                <div className="w-full border-b border-[#CACACA]"></div>

                <span className="mx-5"> Login</span>
                <div className="w-full border-b border-[#CACACA]"></div>
              </div>
              <div className="w-auto ">
                <form onSubmit={handleLoginClick}>
                  <div className="my-8 ">
                    <div className="w-full mb-8 ">
                      <TextInputs
                        type={"text"}
                        title={"Email"}
                        name="email"
                        value={formData.email}
                        placeholder="Enter your Email"
                        setFormData={setFormData}
                        // onInputChange={(e) => onInputChange("phoneNumber", e)} // Bind onInputChange here
                      />
                    </div>
                    <div className="w-full flex items-center">
                      <TextInputs
                        type={passVisibility}
                        title={"Password"}
                        name="password"
                        value={formData.password}
                        placeholder="Enter your Password"
                        setFormData={setFormData}
                        // onInputChange={(e) => onInputChange("phoneNumber", e)} // Bind onInputChange here
                      />
                      <div className="-ml-10 z-2 cursor-pointer">
                        {passVisibility === "password" ? (
                          <VisibilityOffIcon
                            onClick={() => {
                              setpassVisibility("text");
                            }}
                          />
                        ) : (
                          <RemoveRedEyeIcon
                            onClick={() => {
                              setpassVisibility("password");
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <span className="text-[#118615] underline text-sm cursor-pointer">
                        Forgot Password
                      </span>
                    </div>
                  </div>

                  <div className="md:flex items-center">
                    <div className="flex justify-center w-full">
                      <button
                        onMouseDown={() => {
                          setclick(true);
                        }}
                        onMouseUp={() => {
                          setclick(false);
                        }}
                        onClick={handleLoginClick}
                        type="submit"
                        className={`w-full p-1 bg-[#118615]   ${
                          click ? "opacity-75" : "opacity-100"
                        } text-xl text-white rounded-md`}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
                {/* <GoogleLogin
                  clientId="156987578910-u2mg62hrg7dk6d2deunerts475sr59mb.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  cookiePolicy={"single_host_origin"}
                /> */}
                <div className="text-sm text-center my-2 text-[#666666]">
                  Don’t have an account?
                  <Link
                    to={"/newregister"}
                    className="text-[#118615] underline "
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-xs md:text-xl  mt-4 font-bold text-[#118615]">
          "Empower your journey, charge up for sustainability!"
        </div>
      </div>
    </>
  );
};

export default New_login;
