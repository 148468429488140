import React, { useEffect, useState } from "react";

function Cardrevnue(props) {
  const {
    title,
    label,
    Hovering,
    mode,
    initialValue,
    ChargigCosthwr,
    ChargigCostkwh,
    onValueChange,
    childNumber,
    onButtonClick,
  } = props;
  const [hovering, setHovering] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [updatedData, setUpdatedData] = useState("");
  const [totalRates, setTotalRates] = useState(0);
  const [totalMaintenance, setTotalMaintenance] = useState(0);

  console.log(title);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onValueChange(e.target.value);
  };

  useEffect(() => {
    // setHovering(Hovering);
  }, [Hovering]);

  useEffect(() => {
    if (mode === "totalRates") {
      // Calculate total rates based on the input values
      setTotalRates(calculateTotalRates());
    } else if (mode === "totalMaintenance") {
      // Calculate total maintenance based on the input values
      setTotalMaintenance(calculateTotalMaintenance());
    }
  }, [mode, totalRates, totalMaintenance, props.onUpdate]);

  const calculateTotalRates = () => {
    // Assuming that onUpdate callback returns an object containing the input values for each label
    const inputData = props.onUpdate();
    const perKwh = inputData["rates Per Kwh"] || 0;
    const perHour = inputData["Per hour"] || 0;

    return perKwh + perHour;
  };

  const calculateTotalMaintenance = () => {
    // Assuming that onUpdate callback returns an object containing the input values for each label
    const inputData = props.onUpdate();
    const maintenanceCharges = inputData["maintenance charges"] || 0;

    return maintenanceCharges;
  };

  const handleMouseEnter = () => {
    if (!Hovering == false) {
      setHovering(true);
    }
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  const handleUpdateClick = () => {
    setUpdatedData(inputValue);
    setInputValue("");
    onButtonClick(childNumber);
  };

  return (
    <div
      className={`h-20 ${
        title != "Total Charges" && "lg:hover:scale-110"
      } duration-300 mb-4 py-2 w-full lg:w-auto`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* md:w-80 */}
      <div className="w-full lg:w-52 xl:w-72 shadow-lg relative items-center">
        <div
          // className="vertical-line"
          style={{
            position: "absolute",
            top: "15%",
            justifySelf: "center",
            left: "15px",
            width: "5px",
            height: "70%",
            backgroundColor: "black",
            borderRadius: "7px",
            // transform: "translateX(-50%)",
          }}
        ></div>
        <div className="pr-2">
          <div className="flex duration-500 items-center justify-between py-3 text-lg lg:text-xs xl:text-base font-semibold  overflow-hidden">
            {!hovering && (
              <>
                {
                  <div className="mx-4">
                    {title === "Total Charges" && initialValue !== 0 && (
                      <div>
                        <div className="text-xs">
                          <span>per Kwh : </span>
                          {ChargigCostkwh}
                        </div>
                        <hr
                          className="m-0 "
                          style={{
                            height: "1.5px",
                            backgroundColor: "#DBDBDB",
                            opacity: 1,
                          }}
                        />

                        <div className="text-xs">
                          <span>per Hour : </span>
                          {ChargigCosthwr}
                        </div>
                      </div>
                    )}
                    {title !== "Total Charges" && initialValue}
                  </div>
                }

                <div style={{}}>{title}</div>
                {/* marginLeft: "15px" */}
                {title === "Total Charges" && mode === "totalRates" && (
                  <div className="mx-8">{totalRates}</div>
                )}

                {title === "Total Charges" && mode === "totalMaintenance" && (
                  <div className="mx-8">{totalMaintenance}</div>
                )}
              </>
            )}
            {hovering && (
              <>
                {title !== "Total Charges" && (
                  <>
                    <input
                      type="text"
                      className="form-control mx-6"
                      // value={inputValue}
                      value={initialValue}
                      onChange={handleInputChange}
                      placeholder={`Enter ${label}`}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={handleUpdateClick}
                      disabled={!initialValue}
                    >
                      Update
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cardrevnue;
