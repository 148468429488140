import React from "react";
import { Zap, IndianRupee, Activity } from "lucide-react";
import PowerIcon from "@mui/icons-material/Power";
import BoltIcon from "@mui/icons-material/Bolt";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function DashBoardCard({ title, disc, seticon, SubDis }) {
  console.log(title, "kkkkkkkkkkkkkkkkk");

  console.log("gdhsgdjsdgjsdgjh", seticon);

  return (
    // className={`shadow-md hover:scale-110 duration-300 border-2 w-72 md:w-80 h-44 p-3 rounded-xl bg-["#F9FAFE"] flex  flex-col justify-between overflow-hidden`}
    <div
      className={`shadow-md hover:scale-110 duration-300 border-2 w-72 lg:w-52 xl:w-72 h-40 xl:h-44 p-3 rounded-xl bg-["#F9FAFE"] flex flex-col justify-between overflow-hidden`}
    >
      <div className="flex flex-col justify-between">
        {seticon === "PowerIcon" && (
          <PowerIcon sx={{ fontSize: 30 }} color="success" />
        )}
        {seticon === "BoltIcon" && (
          <BoltIcon sx={{ fontSize: 30 }} color="success" />
        )}
        {seticon === "CurrencyRupeeIcon" && (
          <CurrencyRupeeIcon sx={{ fontSize: 30 }} color="success" />
        )}
        {/* <PowerIcon /> */}
      </div>
      <div className="text-xl 2xl:text-2xl font-semibold">
        <p className="">{title}</p>
        <div className="flex items-center">
          <p className="mr-1">{disc}</p>
          {SubDis && <p className="text-sm mt-2">({SubDis})</p>}
        </div>
      </div>
    </div>
  );
}

export default DashBoardCard;
