import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { addpid } from "../../Redux/counterSlice";

const AddpidsModal = ({ showModal, handleClose }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({ pid: "", ChargerName: "" });
  const [validationErrors, setValidationErrors] = useState({
    pid: "",
    chargerName: "",
  });

  // const uid = useSelector((state) => state.Store?.user?.uid);
  const stored = localStorage.getItem("user");
  const storedUsername = JSON.parse(stored);
  console.log(storedUsername, "lllllll");

  const uid = storedUsername?.userData?.uid;
  console.log(uid, "kkkkkkkkk");
  const handleInputChange = (key, e) => {
    const value = e.target.value;
    setInputValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform custom validation
    const errors = {};
    if (inputValue.pid.trim() === "") {
      errors.pid = "PID is required";
    }
    if (inputValue.pid.length > 0 && inputValue.ChargerName.trim() === "") {
      errors.chargerName = "Charger Name is required";
    }

    // If there are errors, update the state and return
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const sendData = {};
    sendData.pid = inputValue.pid;
    sendData.ChargerName = inputValue.ChargerName;
    sendData.uid = uid;
    if (
      inputValue.pid[0].toLowerCase() == "h" &&
      inputValue.pid[1].toLowerCase() == "e"
    ) {
      sendData.category = "Home Charger";
      if (inputValue.pid[2].toLowerCase() == "s") {
        sendData.SubCategory = "Four Wheeler";
      }
      if (inputValue.pid[2].toLowerCase() == "l") {
        sendData.SubCategory = "Two Wheeler";
      }
    }
    if (
      inputValue.pid[0].toLowerCase() == "p" &&
      inputValue.pid[1].toLowerCase() == "e"
    ) {
      sendData.category = "Public Charger";
      if (inputValue.pid[2].toLowerCase() == "s") {
        sendData.SubCategory = "Four Wheeler";
      }
      if (inputValue.pid[2].toLowerCase() == "l") {
        sendData.SubCategory = "Two Wheeler";
      }
    }

    const response = await dispatch(addpid(sendData));
    if (response.payload.message == "add pid successfully") {
      console.log("your pid added");
    } else {
      console.log("proceesing");
    }
    console.log("response", response);
    handleClose();
    setInputValue({ ...inputValue, ChargerName: "", pid: "" });
    window.location.reload();
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Pids</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group controlId="formInput">
            <Form.Label>Enter PID:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter PID"
              value={inputValue.pid}
              onChange={(e) => {
                handleInputChange("pid", e);
              }}
              required
              style={{ borderColor: validationErrors.pid ? "red" : "" }}
            />
            {validationErrors.pid && (
              <div style={{ color: "red" }}>{validationErrors.pid}</div>
            )}
          </Form.Group>
          {inputValue.pid.length > 0 && (
            <Form.Group controlId="formInput">
              <Form.Label>Enter Charger Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Charger Name"
                value={inputValue.ChargerName}
                onChange={(e) => {
                  handleInputChange("ChargerName", e);
                }}
                required
                style={{
                  borderColor: validationErrors.chargerName ? "red" : "",
                }}
              />
              {validationErrors.chargerName && (
                <div style={{ color: "red" }}>
                  {validationErrors.chargerName}
                </div>
              )}
            </Form.Group>
          )}
          {/* Add a submit button or form submission logic here */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button
          className="rounded-md py-2 bg-[#118615] hover:bg-blue-600"
          style={{ marginLeft: "10px" }}
          onClick={handleSubmit}
        >
          Submit
        </button>
        {/* <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default AddpidsModal;
