export function calculateTotalAmountByDate(data) {
    const newArray = [];
    const map = new Map();

    data.forEach(entry => {
            const date = entry.Date;
            const amount = parseInt(entry.payment) || 0; // Treat undefined or missing amount as 0

            if (map.has(date)) {
                map.set(date, map.get(date) + amount);
            } else {
                map.set(date, amount);
            }
      
    });
   
    map.forEach((amount, date) => {
        newArray.push({ date, amount });
    });

    // Sort the newArray by date
    newArray.sort((a, b) => {
        const dateA = a.date.split('/').reverse().join('/');
        const dateB = b.date.split('/').reverse().join('/');
        return dateA.localeCompare(dateB);
    });

    return newArray;
}