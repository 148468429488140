import React, { useEffect, useState } from "react";
import "./navbar.css";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import logo1 from "../../genric/Assets/Jouls.png"
// import jouls from "../../../public/jouls1.png"
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// const url = "http://165.22.223.26:5000";
const url = "https://backend.jouls.co.in";
// const url="http://localhost:5200"

const Nav_bar = ({ username }) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname, "pathname");
  const populateBankDetails = location.pathname === "/RevenueManagemant";
  const [showModal, setShowModal] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState("");

  const handleLinkClick = (title) => {
    setExpanded(false); // Close the Navbar when a link is clicked
    if (title === "Logout") {
      localStorage.removeItem("user");
      console.log("Logout Done");
    }
  };

  const handleClick = () => {
    setShowModal(true);
  };

  const stored = localStorage.getItem("user");
  const storedUsername = JSON.parse(stored);
  const uid = storedUsername?.userData?.uid;
  console.log(storedUsername, "lllllll");
  const profile = storedUsername?.user?.pic_url;
  console.log("anme", storedUsername?.userData?.name);
  // console.log("pic1",profile)
  // const storedUsername = useSelector((state) => state.Store.user?.user?.name);
  // console.log(pidsData1,"harshharsh");

  const sample = () => {
    // Navigate to the editProfile page
    navigate("/editProfile");
  };

  // Get today's date
  const today = new Date();
  const formattedDate = `${today.getDate()}/${
    today.getMonth() + 1
  }/${today.getFullYear()}`;

  const menuItems = [
    {
      title: "Dashboard",
      navigate: "/dashboard",
    },
    {
      title: "History",
      navigate: "/history",
    },
    {
      title: "Revenue Management",
      navigate: "/RevenueManagemant",
    },
    {
      title: "Add Charger",
      navigate: "/addcharger",
    },
    {
      title: "Support",
      navigate: "/Support",
    },
    {
      title: "Logout",
      navigate: "/newlogin",
    },
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${url}/user/UserPanelRoutes/${uid}?populateBankDetails=${populateBankDetails}`
        );
        if (!response.ok) {
          console.log("Failed to fetch user data");
          // throw new Error("Failed to fetch user data");
        }
        const userData = await response.json();
        console.log("ddeeeee", userData);
        setData(userData);
      } catch (error) {
        console.log("Error fetching user data:", error.message);
      }
    };
    fetchUserData();
  }, []);

  return (
    <div className="shadow-bottom bg-white fixed w-full z-10 text-sm md:text-xl overflow-hidden">
      <div className="flex justify-between items-center px-2 d-none d-lg-flex relative h-16">
        <div className="hidden md:block">
          <div className="flex items-center">
            <img src="Union.png" alt="Notification" className="h-8 ml-3" />
            <span className="ml-4">{formattedDate}</span>
          </div>
        </div>
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-center -z-10 items-center">
          <img src="jouls1.png" alt="Icon" className="h-24 object-contain" />
        </div>

        <div className="flex  items-center">
          {storedUsername && (
            <span className="mr-4">Welcome! {data?.name}</span>
          )}

          <div className="cursor-pointer" onClick={sample}>
            <img
              src={data?.pic_url}
              alt="Profile Icon"
              className="h-8"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "2px solid #118615",
                padding: "2px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex d-lg-none">
        <Navbar
          expand="lg"
          className="bg-body-tertiary"
          fixed="top"
          expanded={expanded}
          onToggle={setExpanded}
        >
          <Container>
            <img src="jouls1.png" alt="Icon" className="w-28 object-contain" />
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              style={{
                backgroundColor: "#fff",
                borderColor: "green",
                color: "green",
              }}
            />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {menuItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.navigate}
                    className="text-gray-500 no-underline py-2 ml-4 hover:bg-light-400" // Add hover:bg-light class for clickable effect
                    onClick={() => {
                      handleLinkClick(item.title);
                    }} // Close the Navbar on link click
                  >
                    {item.title}
                  </Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default Nav_bar;
