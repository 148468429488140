import React, { useState } from "react";
import "./circle.css"; // Import CSS file for styling

const Circle = ({ handleShow }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={{ marginTop: "50px", height: 120 }}>
      <div className="some-surrounding-elements">
        {/* Other surrounding elements */}
      </div>

      <svg
        width="100"
        height="100"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleShow}
      >
        <g className="circle-group">
          <circle cx="50" cy="50" r="40" fill="#118615" className="circle" />
          {/* Green plus icon */}
          <text
            x="50"
            y="58"
            fontSize="24"
            fill={isHovered ? "transparent" : "green"}
            textAnchor="middle"
          >
            +
          </text>
          <line
            x1="30"
            y1="50"
            x2="70"
            y2="50"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="50"
            y1="30"
            x2="50"
            y2="70"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          {isHovered && (
            <g className="tooltip">
              <rect
                x="1"
                y="80"
                width="100"
                height="50"
                fill="#333"
                rx="5"
                ry="5"
                opacity="0"
              />
              <text x="50" y="110" textAnchor="middle" fill="white" opacity="0">
                Add Product
              </text>
            </g>
          )}
        </g>
      </svg>
    </div>
  );
};

export default Circle;
