import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import { editChargerName } from "../src/Redux/counterSlice"; // Update the path to your Redux slice file

const EditChargerModal = ({ showModal1, handleClose, chargerName, pid }) => {
  const [editedChargerName, setEditedChargerName] = useState(chargerName);
  const [validationError, setValidationError] = useState("");
  const dispatch = useDispatch(); // Initialize dispatch function from react-redux

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform validation
    if (editedChargerName.trim() === "") {
      setValidationError("Charger Name is required");
      return;
    }

    // Dispatch editChargerName action with updated charger name
    dispatch(editChargerName({ pid, newChargerName: editedChargerName }));
    window.location.reload();
    handleClose();
  };

  return (
    <Modal show={showModal1} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Charger</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formChargerID">
            <Form.Label>Charger ID:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Charger ID"
              value={pid}
              disabled // Make the input field disabled
            />
          </Form.Group>
          <Form.Group controlId="formChargerName">
            <Form.Label>Charger Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Charger Name"
              value={editedChargerName}
              onChange={(e) => setEditedChargerName(e.target.value)}
              isInvalid={validationError}
            />
            <Form.Control.Feedback type="invalid">
              {validationError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button
          className="rounded-md py-2 bg-[#118615] hover:bg-blue-600"
          style={{ marginLeft: "10px" }}
          onClick={handleSubmit}
        >
          Save
        </button>
        {/* <Button variant="primary" onClick={handleSubmit}>
        Save
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default EditChargerModal;
