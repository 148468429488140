import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Startscreen = () => {
    const navigate=useNavigate()
    // const isAuthenticated = localStorage.getItem("user")
    const isAuthenticated = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).userData.token
    : false;
    console.log("isAuthenticated",isAuthenticated)
    useEffect(()=>{

        setTimeout(() => {
            if(isAuthenticated){
                navigate("/dashboard")
            }
            else{
                navigate("/newlogin")
            }
        }, 2000);
    },[])
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <img src='/images/startsceenlogo.png' alt='Start Screen Logo' />
    </div>
  );
};

export default Startscreen;
