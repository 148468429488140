// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//     apiKey: "AIzaSyAb3A5q0v05w4qS5Cm2Ix3kd8NEP9cGK6o",
//     authDomain: "wens-force-portal-3ad2c.firebaseapp.com",
//     projectId: "wens-force-portal-3ad2c",
//     storageBucket: "wens-force-portal-3ad2c.appspot.com",
//     messagingSenderId: "146747004601",
//     appId: "1:146747004601:web:095a3907229e2560a0ed68",
//     measurementId: "G-KJEKYPWDDY"
//   };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrKOjb9RJimuC89IRkkro86PPR6nCNgD0",
  authDomain: "sylvan-epoch-414511.firebaseapp.com",
  projectId: "sylvan-epoch-414511",
  storageBucket: "sylvan-epoch-414511.appspot.com",
  messagingSenderId: "156987578910",
  appId: "1:156987578910:web:20bdea7158436cfe4e6c0f",
  measurementId: "G-KJ02SH505E"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);