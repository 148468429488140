import React from 'react'

const Charger = () => {
  return (
    <div>
      Hello Aman Sir 
    </div>
  )
}

export default Charger
