export const generateTimesArray = () => {
    const currentTime = new Date();
    let tempTimes = [];
    for (let i = 0; i < 7; i++) {
      const newTime = new Date(currentTime.getTime() + (i * 15 * 60000));
      const hours = newTime.getHours().toString().padStart(2, '0'); // Get hours in 24-hour format
      const minutes = newTime.getMinutes().toString().padStart(2, '0'); // Get minutes
      const formattedTime = `${hours}:${minutes}`; // Combine hours and minutes
      tempTimes.push(formattedTime);
    }
    return tempTimes;
  };
