import React, { useEffect, useState } from "react";
import Chart from "./chart.js";
import LineChart from "./linechart.js";
import Sidebar from "../Sidebar/Sidebar.jsx";
import Table from "../../genric/Table/Table.js";
import { generateTimesArray } from "../../genric/XlabelforHistory/xlabelforHistory.js";
import MqttYlabel from "./ylabelData/Ylabeldata.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllChargerHistory,
  getAllPids,
  getUserDetails,
} from "../../Redux/counterSlice.js";
import { useNavigate } from "react-router-dom";
import {
  formatToThreeDecimalPlaces,
  getDateRangeDashboard,
  getEnergyUsedForDatesDashboard,
  getTotalTimeForEachDate,
} from "../../genric/labelforDashboard/label.js";
// import "./Container.css";

const History = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const stored = localStorage.getItem("user");
    console.log("stored", stored);
    if (!stored) {
      navigate("/");
    }
  }, []);

  const stored = localStorage.getItem("user");
  const storedUsername = JSON.parse(stored);
  const uid = storedUsername?.userData?.uid;
  console.log("uid", uid);
  const [collapsed, setCollapsed] = useState(true);
  const [ChangeData, setChangeData] = useState([]);
  const [DummyDataforTable, setDummyDataforTable] = useState([1, 2, 3, 4, 5]);
  const [SelectChargerData, setSelectedChargerData] = useState([]);
  console.log("ChangeData", ChangeData);
  const [rtl, setRtl] = useState(false);
  const [selectedCharger, setSelectedCharger] = useState("all"); // State to hold the selected charger
  const YlabelchargingData = useSelector((state) => state.Store.ylabelData);
  let [data, setdata] = useState([]);
  const [xlabelData, setXlabelData] = useState([]);
  const [ylabelData, setYlabelData] = useState([]);
  const [ylabelDataForTime, setYlabelDataForTime] = useState([]);
  useEffect(() => {
    data = YlabelchargingData.map((e, i) => e.average);
    setdata(data);
  }, [YlabelchargingData]);

  const populateBankDetails = false;
  const populateChargerHistoryData = true;
  const populatePidsDetails = true;

  useEffect(() => {
    const fetchdata = async () => {
      // await dispatch(getAllChargerHistory(uid));
      await dispatch(
        getUserDetails({
          uid,
          populateBankDetails,
          populateChargerHistoryData,
          populatePidsDetails,
        })
      );
    };
    fetchdata();
  }, [dispatch, uid]);

  // useEffect(() => {
  //   const fetchdata = async () => {
  //     await dispatch(getAllPids(uid));
  //   };
  //   fetchdata();
  // }, [dispatch, uid]);

  const xLabelData = useSelector((state) => state.Store.xlabeldata);
  const getAllChargerHistoryData = useSelector(
    (state) => state.Store.getAllChargerHistory
  );
  const getAllChargerData = useSelector((state) => state.Store.getAllPids);
  console.log("🚀 ~ History ~ getAllChargerData:", getAllChargerData);

  // Function to handle selection of charger
  const handleChargerChange = (event) => {
    const filterCriteria = event.target.value;

    let filteredData =
      filterCriteria === "all"
        ? getAllChargerHistoryData
        : getAllChargerHistoryData.filter(
            (user) => user.pid === filterCriteria
          );

    let counter = 1;
    let datafortable = filteredData.map((user, index) => ({
      // serialno: getAllChargerHistoryData.length - index,
      serialno: counter++,
      Date: user?.Date,
      chargername: user.ChargerName,
      usedby: user?.UsedBy,
      starttime: user?.StartTime,
      endtime: user?.EndTime,
      enegryused: user?.EnergyUsed,
      chargingCost: formatToThreeDecimalPlaces(user?.chargingCost),
      inputamount: user?.inputCost,
    }));

    // datafortable = datafortable.reverse();
    setChangeData(datafortable);
    setSelectedCharger(filterCriteria);
  };

  const columns = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Date",
      accessor: "Date",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "Used by",
      accessor: "usedby",
    },
    {
      header: "Start time",
      accessor: "starttime",
    },
    {
      header: "End time",
      accessor: "endtime",
    },
    {
      header: "Energy used",
      accessor: "enegryused",
      unit: "Wh",
    },
    {
      header: " Used Charging Cost",
      accessor: "chargingCost",
      unit: "₹",
    },
    {
      header: "Input Amount",
      accessor: "inputamount",
      unit: "₹",
    },
  ];

  useEffect(() => {
    setSelectedChargerData(
      getAllChargerData.map((user) => ({
        chargername: user.ChargerName,
        Pid: user.pid,
      }))
    );
  }, [getAllChargerData]);

  const clickaddcharge = () => {
    // navigate("/addcharger");
  };

  let chargerStatusMessage = null;

  if (selectedCharger === "all") {
    chargerStatusMessage = (
      <p>You have selected all chargers. Please select a particular charger.</p>
    );
    // } else {
    //   const selectedChargerData = datafortable.find(
    //     (user) => user.chargername === selectedCharger
    //   );
    //   if (selectedChargerData?.status === "Idle") {
    //     chargerStatusMessage = (
    //       <b>
    //         <p style={{ color: "#dbdbdb" }}>Your charger status is idle.</p>
    //       </b>
    //     );
    //   }
  }

  console.log(selectedCharger, "asdfgggggggh");

  useEffect(() => {
    let datafortable = getAllChargerHistoryData.map((user, index) => ({
      serialno: getAllChargerHistoryData.length - index,
      Date: user?.Date,
      chargername: user.ChargerName,
      usedby: user?.UsedBy,
      starttime: user?.StartTime,
      endtime: user?.EndTime,
      enegryused: user?.EnergyUsed,
      enegryused: user?.EnergyUsed,
      chargingCost: formatToThreeDecimalPlaces(user?.chargingCost),
      inputamount: user?.inputCost,
    }));
    datafortable = datafortable.reverse();
    setChangeData(datafortable);
  }, [getAllChargerHistoryData]);

  useEffect(() => {
    if (ChangeData.length == 0) {
      const data = [{ serialno: 1 }, { serialno: 2 }, { serialno: 3 }];
      setDummyDataforTable(data);
    }
    const xlabel = getDateRangeDashboard(ChangeData);
    if (xlabel.length > 0) {
      const ylabel = getEnergyUsedForDatesDashboard(ChangeData, xlabel);
      const ylabelforTime = getTotalTimeForEachDate(xlabel, ChangeData);
      console.log("ylabelforTime", ylabelforTime, ChangeData, xlabel);
      setYlabelData(ylabel);
      setYlabelDataForTime(ylabelforTime);
    }

    setXlabelData(xlabel);
  }, [ChangeData]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <MqttYlabel />
      <Sidebar collapsed={collapsed} rtl={rtl} />
      <div className="w-full py-8 px-4 lg:ml-20 mt-12 overflow-hidden">
        <div className="w-full mb-8 text-lg md:text-xl lg:text-3xl font-semibold text-[#605C5C] capitalize ">
          Charging History
        </div>

        <div>
          <label className="border rounded-lg shadow-md p-2 px-4 mb-8">
            <span className="mr-2 text-sm xl:text-lg font-semibold capitalize">
              Select Your Charger:
            </span>
            <select
              value={selectedCharger}
              onChange={handleChargerChange}
              className="border-none outline-none"
            >
              <option value="all">All Chargers</option>
              {SelectChargerData.map((charger, index) => (
                <option key={index} value={charger.Pid}>
                  {charger.chargername}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="xl:flex w-full gap-4 p-2 shadow-xl rounded-2xl border overflow-hidden relative">
          {chargerStatusMessage && (
            //  bg-white bg-opacity-90 py-2
            <div className="absolute top-0 left-0 right-0 flex items-center justify-center text-sm xl:text-lg font-semibold text-[#605C5C] capitalize  h-full">
              {chargerStatusMessage}
            </div>
          )}
          {selectedCharger === "all" ? (
            <div className="w-full blur">
              <Chart
                xLabelData={xlabelData}
                ylabelData={ylabelData}
                topName={"Energy"}
                ylabelName="Energy"
                xlabelName={"Date"}
              />
            </div>
          ) : (
            <div className="w-full">
              <Chart
                xLabelData={xlabelData}
                ylabelData={ylabelData}
                topName={"Energy"}
                ylabelName="Energy"
                xlabelName={"Date"}
              />
            </div>
          )}
          {selectedCharger === "all" ? (
            <div className="w-full blur mt-8 xl:mt-0">
              <LineChart
                xLabelData={xlabelData}
                ylabelData={ylabelDataForTime}
                xlabelName={"Date"}
                ylabelName={"Time"}
              />
            </div>
          ) : (
            <div className="w-full mt-8 xl:mt-0">
              <LineChart
                xLabelData={xlabelData}
                ylabelData={ylabelDataForTime}
                xlabelName={"Date"}
                ylabelName={"Time"}
              />
            </div>
          )}
        </div>

        <div className="mt-4">
          {ChangeData && ChangeData.length > 0 ? (
            <Table
              greenClicked={clickaddcharge}
              columns={columns}
              data={ChangeData}
              pageSize={10}
              greenButtonText={"Export All"}
            />
          ) : (
            <>
              <Table
                greenClicked={clickaddcharge}
                columns={columns}
                data={DummyDataforTable}
                pageSize={10}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;

{
  /* <div className="mt-4">
{!ChangeData && ChangeData.length > 0 ? (
  <Table
    greenClicked={clickaddcharge}
    columns={columns}
    data={ChangeData}
    pageSize={10}
  />
) : (
  <>
    <div className="relative">
      <p className="absolute bg-red-100 text-[#605C5C] top-50 right-0 left-0 text-lg md:text-3xl text-center font-semibold z-10">
        Data not available
      </p>
      <div className="blur-sm bg-green-100 ">
        <Table
          greenClicked={clickaddcharge}
          columns={columns}
          data={DummyDataforTable}
          pageSize={10}
        />
      </div>
    </div>
  </>
)}
</div> */
}
