export const calculateEnergySum = (dataArray) => {
    let sum = 0;
    dataArray.forEach((item) => {
      if (item.EnergyUsed) {
        sum += parseInt(item.EnergyUsed);
      }
    });
    return sum;
  };

  export const calculateAmountSum = (dataArray) => {
    let sum = 0;
    dataArray.forEach((item) => {
      if (item.chargingCost && !isNaN(item.chargingCost)) {
        // sum += parseInt(item.chargingCost);
        sum += Number(item.chargingCost);
      }
    });
    // return sum;
    return  parseFloat(sum.toFixed(1));;;
  };
  

 export const calculateTimeDifference = (dataArray) => {
    let totalMinutes = 0;
    dataArray.forEach((item) => {
      if (item.StartTime && item.EndTime) {
        const startTime = item.StartTime.split(":");
        const endTime = item.EndTime.split(":");
        let startHour = parseInt(startTime[0]);
        const startMinute = parseInt(startTime[1]);
        let endHour = parseInt(endTime[0]);
        const endMinute = parseInt(endTime[1]);
        
        // Adjust endHour if it's less than startHour (next day)
        if (endHour < startHour) {
          endHour += 24;
        }
        
        const totalStartMinutes = startHour * 60 + startMinute;
        const totalEndMinutes = endHour * 60 + endMinute;
        
        totalMinutes += totalEndMinutes - totalStartMinutes;
      }
    });
  
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    if (hours === 0) {
      return `${minutes} min.`;
    } else {
      return `${hours} Hrs ${minutes} min.`;
    }
  };
  

  // get difference start time end time
  export function calculateTimeDifferenceStartTimeEndTime(startTime, endTime) {
    // Check if either startTime or endTime is undefined
    if (!startTime || !endTime) {
        return "Not available";
    }

    // Convert start time and end time to Date objects
    var start = new Date("01/01/2024 " + startTime);
    var end = new Date("01/01/2024 " + endTime);

    // Check if either start or end time is invalid
    if (isNaN(start.getTime()) || isNaN(end.getTime()) || end < start) {
        return "Invalid time";
    }

    // Calculate the difference in milliseconds
    var difference = Math.abs(end.getTime() - start.getTime());

    // Calculate hours, minutes, and seconds
    var totalSeconds = Math.floor(difference / 1000);
    var hours = Math.floor(totalSeconds / 3600);
    var minutes = Math.floor((totalSeconds % 3600) / 60);
    var seconds = totalSeconds % 60;

    // Construct the time difference string using template literals
    var timeDifference = "";

    if (hours > 0) {
        timeDifference += `${hours} hr `;
    }

    if (minutes > 0) {
        timeDifference += `${minutes} min `;
    }

    if (seconds > 0) {
        timeDifference += `${seconds} sec`;
    }

    // If both hours, minutes, and seconds are 0, return "0 sec"
    if (hours === 0 && minutes === 0 && seconds === 0) {
        return "0 sec";
    }

    return timeDifference.trim();
}

 
  