import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PowerIcon from "@mui/icons-material/Power";
import SupportIcon from "@mui/icons-material/Support";
import LoginIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const Sidebar = ({ collapsed, rtl }) => {
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setSidebarVisible(true);
  }, []);

  const handleClick = () => {
    navigate("/dashboard");
    window.location.reload();
  };

  const handleClick1 = () => {
    navigate("/RevenueManagemant");
    window.location.reload();
  };

  const handleClick2 = () => {
    navigate("/addcharger");
    window.location.reload();
  };

  const handleClick3 = () => {
    navigate("/Support");
  };

  const handleClick4 = () => {
    localStorage.removeItem("user");
    navigate("/newlogin");
  };

  const handleClick5 = () => {
    navigate("/history");
  };

  const handleClick6 = () => {
    navigate("/editProfile");
  };

  return (
    <ProSidebar
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#C1E0C2",
        position: "fixed",
        marginTop: "62px",
        border: 0,
      }}
      collapsed={collapsed}
      rtl={rtl}
      breakPoint="md"
      className={sidebarVisible ? "sidebar-visible" : "sidebar-hidden"}
    >
      <Menu>
        <Tooltip title="Dashboard" placement="right" arrow>
          <MenuItem
            icon={<DashboardIcon />}
            onClick={handleClick}
            className={location.pathname === "/dashboard" ? " bg-white" : ""}
          ></MenuItem>
        </Tooltip>
        <Tooltip title="History" placement="right" arrow>
          <MenuItem
            icon={<HistoryIcon />}
            onClick={handleClick5}
            className={location.pathname === "/history" ? "bg-white" : ""}
          ></MenuItem>
        </Tooltip>
        <Tooltip title="Revenue Management" placement="right" arrow>
          <MenuItem
            icon={<AttachMoneyIcon />}
            onClick={handleClick1}
            className={
              location.pathname === "/RevenueManagemant" ? "bg-white" : ""
            }
          ></MenuItem>
        </Tooltip>
        <Tooltip title="Add Charger" placement="right" arrow>
          <MenuItem
            icon={<PowerIcon />}
            onClick={handleClick2}
            className={location.pathname === "/addcharger" ? "bg-white" : ""}
          ></MenuItem>
        </Tooltip>
        <Tooltip title="Support" placement="right" arrow>
          <MenuItem
            icon={<SupportIcon />}
            onClick={handleClick3}
            className={location.pathname === "/Support" ? "bg-white" : ""}
          ></MenuItem>
        </Tooltip>
        <Tooltip title="Profile" placement="right" arrow>
          <MenuItem
            icon={<AccountCircleIcon />}
            onClick={handleClick6}
            className={location.pathname === "/editProfile" ? "bg-white" : ""}
          ></MenuItem>
        </Tooltip>
      </Menu>
      <Menu className="absolute bottom-24">
        <Tooltip title="Logout" placement="right" arrow>
          <MenuItem icon={<LoginIcon />} onClick={handleClick4}></MenuItem>
        </Tooltip>
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;
