import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Component/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const EditProfile = () => {
  const url = "https://backend.jouls.co.in";
  const sharedClasses = {
    flex: "flex",
    flexShrink: "flex-shrink-0",
    itemsCenter: "items-center",
    spaceX: "space-x-4",
    mt: "mt-6",
    borderT: "border-t",
    border: "border",
    pt: "pt-5",
    smFlex: "sm:flex",
    smSpaceX: "sm:space-x-4",
    smItemsCenter: "sm:items-center",
    textSm: "text-sm",
    fontMedium: "font-medium",
    textZinc500: "text-zinc-500",
    smW40: "sm:w-40",
    mt1: "mt-1",
    mt0: "mt-0",
    smColSpan2: "sm:col-span-2",
    ml4: "ml-20",
    bgBlue500: "bg-blue-500",
    hoverBgBlue600: "hover:bg-blue-600",
    textWhite: "text-white",
    fontBold: "font-bold",
    py1: "py-1",
    px4: "px-9",
    rounded: "rounded",
    textBlue600: "text-blue-600",
    hoverTextBlue800: "hover:text-blue-800",
    divideY: "divide-y",
    divideZinc200: "divide-zinc-200",
    leading6: "leading-6",
    textZinc900: "text-zinc-900",
    minW0: "min-w-0",
    textBlue800: "text-blue-800",
  };
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [bankDetails, setBankDetails] = useState([]);
  const navigate = useNavigate();
  const [team1DataVisible, setTeam1DataVisible] = useState(false);
  const [team2DataVisible, setTeam2DataVisible] = useState(false);
  const populateBankDetails = true;
  const toggleTeam1Data = () => {
    setTeam1DataVisible(!team1DataVisible);
  };

  const toggleTeam2Data = () => {
    setTeam2DataVisible(!team2DataVisible);
  };
  const stored = localStorage.getItem("user");
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    address: "",
    phoneNumber: "",
    pic_url: "",
  });
  console.log(formData, "dkjdsdsjds");
  const [imageFile, setImageFile] = useState(null);
  const [previewSource, setPreviewSource] = useState(null);
  const [Source, setSource] = useState(null);

  const fileInputRef = useRef(null);

  //   useEffect(() => {
  //     //   const stored = localStorage.getItem("user");
  //     //   const save = JSON.parse(stored)?.user;
  //     //   const save1 = JSON.parse(stored);
  //     //   setSource(save);

  //     //   console.log("stored", save1);

  //       if (!stored) {
  //           navigate("/");
  //       } else {
  //           const data = JSON.parse(stored);
  //           const formatdata = data.user;
  //           console.log("dadatata", data.user);
  //         //   setFormData({
  //         //       ...formData,
  //         //       name: formatdata?.name,
  //         //       email: formatdata?.email,
  //         //       // phoneNumber:formatdata.
  //         //       // Add other properties here
  //         //   });
  //       }
  //   }, []);

  const storedUsername = JSON.parse(stored);
  const uid = storedUsername?.userData?.uid;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://backend.jouls.co.in/user/UserPanelRoutes/${uid}?populateBankDetails=${populateBankDetails}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const userData = await response.json();
        console.log("Fetched user data:", userData); // Logging userData
        setSource(userData);
        setFormData({ ...userData });
        // Check if bank details exist
        if (userData.bankDetails) {
          setBankDetails(userData.bankDetails); // Set bank details to state
        } else {
          setBankDetails(null); // Reset bank details state
        }
      } catch (error) {
        console.log("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, [uid, populateBankDetails]);

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };
  console.log(bankDetails, "abcdefgh");
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      const file = e.target.files[0];
      if (file) {
        setFormData({
          ...formData,
          pic_url: file,
        });
        setImageFile(file);
        previewFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const [editName, setEditName] = useState(false);
  const [editUserName, setEditUserName] = useState(false);
  const [editPhonenumber, setEditPhoneNumber] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  const handleEditName = () => {
    setEditName(true);
  };

  const handleEditUserName = () => {
    setEditUserName(true);
  };
  const handleEditPhoneNumber = () => {
    setEditPhoneNumber(true);
  };
  const handleEditAddress = () => {
    setEditAddress(true);
  };
  const handleEditEmail = () => {
    setEditEmail(true);
  };

  const handleSaveName = async () => {
    try {
      const updatedData = {
        name: formData.name, // Assuming formData is an object with a 'name' property
      };
      const response = await fetch(
        `${url}/user/UserPanelRoutes/usersupdate/${uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        // Update the Source state with the new name
        setSource((prevSource) => ({
          // ...prevSource,
          // name: formData.name,
          response,
        }));
        // Show a success message
        toast.success("Name updated successfully");
        setEditName(false); // Exit edit mode
      } else {
        console.error("Failed to update name");
        toast.error("Failed to update name");
      }
    } catch (error) {
      console.error("Error updating name:", error);
      toast.error("Failed to update name");
    }
  };
  const handleSaveAddress = async () => {
    try {
      const updatedData = {
        address: formData.address, // Assuming formData is an object with a 'name' property
      };
      const response = await fetch(
        `${url}/user/UserPanelRoutes/usersupdate/${uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        // Update the Source state with the new name
        setSource((prevSource) => ({
          // ...prevSource,
          // name: formData.name,
          response,
        }));
        // Show a success message
        toast.success("Address updated successfully");
        setEditAddress(false); // Exit edit mode
      } else {
        console.error("Failed to update Address");
        toast.error("Failed to update Address");
      }
    } catch (error) {
      console.error("Error updating Address:", error);
      toast.error("Failed to update Address");
    }
  };
  const handleSaveEmail = async () => {
    try {
      const updatedData = {
        email: formData.email, // Assuming formData is an object with a 'name' property
      };
      const response = await fetch(
        `${url}/user/UserPanelRoutes/usersupdate/${uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        // Update the Source state with the new name
        setSource((prevSource) => ({
          // ...prevSource,
          // name: formData.name,
          response,
        }));
        // Show a success message
        toast.success("Email updated successfully");
        setEditEmail(false); // Exit edit mode
      } else {
        console.error("Failed to update Email");
        toast.error("Failed to update Email");
      }
    } catch (error) {
      console.error("Error updating Email:", error);
      toast.error("Failed to update Email");
    }
  };

  const handleSaveUserName = async () => {
    // Send a request to update the username on the server

    try {
      const updatedData = {
        username: formData.username, // Assuming formData is an object with a 'name' property
      };
      const response = await fetch(
        `${url}/user/UserPanelRoutes/usersupdate/${uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        // Update the Source state with the new name
        setSource((prevSource) => ({
          // ...prevSource,
          // name: formData.name,
          response,
        }));
        // Show a success message
        toast.success("Username updated successfully");
        setEditUserName(false); // Exit edit mode
      } else {
        console.error("Failed to update username");
        toast.error("Failed to update username");
      }
    } catch (error) {
      console.error("Error updating username:", error);
      toast.error("Failed to update username");
    }
  };
  const handleSavePhoneNumber = async () => {
    // Send a request to update the username on the server

    try {
      const updatedData = {
        phoneNumber: formData.phoneNumber, // Assuming formData is an object with a 'name' property
      };
      const response = await fetch(
        `${url}/user/UserPanelRoutes/usersupdate/${uid}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        // Update the Source state with the new name
        setSource((prevSource) => ({
          // ...prevSource,
          // name: formData.name,
          response,
        }));
        // Show a success message
        toast.success("Phone Number updated successfully");
        setEditPhoneNumber(false); // Exit edit mode
      } else {
        console.error("Failed to update Phone Number");
        toast.error("Failed to update Phone Number");
      }
    } catch (error) {
      console.error("Error updating Phone Number:", error);
      toast.error("Failed to update Phone Number");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar collapsed={collapsed} rtl={rtl} />
      <div className="w-full py-5">
        {/* <div className="flex justify-center"> */}
        <div className="max-w-6xl mx-auto mt-16">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-5 py-5 sm:p-6">
              <div
                className={`${sharedClasses.flex} ${sharedClasses.itemsCenter} ${sharedClasses.spaceX}`}
              >
                <div className={sharedClasses.flexShrink}>
                  <img
                    className="h-24 w-24 rounded-full"
                    src={formData?.pic_url}
                    alt="Profile image"
                  />
                </div>
                <div className={sharedClasses.minW0}>
                  <h2
                    className={`${sharedClasses.textLg} ${sharedClasses.fontMedium} ${sharedClasses.textZinc900} truncate`}
                  >
                    {formData?.name}
                  </h2>
                  {/* <p className={`${sharedClasses.textSm} ${sharedClasses.textZinc500} truncate`}>Member since 3/12/2024</p> */}
                </div>
              </div>

              <div className={sharedClasses.mt}>
                <dl
                  className={`${sharedClasses.spaceY8} ${sharedClasses.divideY} ${sharedClasses.divideZinc200}`}
                >
                  <div
                    className={`${sharedClasses.smFlex} ${sharedClasses.smSpaceX} ${sharedClasses.smItemsCenter}`}
                  >
                    <dt
                      className={`${sharedClasses.textSm} ${sharedClasses.fontMedium} ${sharedClasses.textZinc500} ${sharedClasses.smW40}`}
                    >
                      Name
                    </dt>
                    {editName ? (
                      <>
                        <input
                          type="text"
                          value={formData.name}
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                          className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:border-blue-500"
                        />
                        <button
                          onClick={handleSaveName}
                          className="ml-2 bg-blue-500 text-white px-3 py-1 rounded-md"
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className={` w-full items-center justify-between px-5 ${sharedClasses.mt1} ${sharedClasses.textSm} ${sharedClasses.textZinc900} ${sharedClasses.smMt0} ${sharedClasses.smColSpan2} ${sharedClasses.smFlex} ${sharedClasses.smItemsCenter}`}
                        >
                          {formData?.name}
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className={`${sharedClasses.ml4}  cursor-pointer`}
                            onClick={handleEditName}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={`${sharedClasses.smFlex} ${sharedClasses.smSpaceX} ${sharedClasses.smItemsCenter}`}
                  >
                    <dt
                      className={`${sharedClasses.textSm} ${sharedClasses.fontMedium} ${sharedClasses.textZinc500} ${sharedClasses.smW40}`}
                    >
                      User Name
                    </dt>
                    {editUserName ? (
                      <>
                        <input
                          type="text"
                          value={formData.username}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              username: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:border-blue-500"
                        />
                        <button
                          onClick={handleSaveUserName}
                          className="ml-2 bg-blue-500 text-white px-3 py-1 rounded-md"
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className={` w-full flex  items-center  justify-between px-5 ${sharedClasses.mt1} ${sharedClasses.textSm} ${sharedClasses.textZinc900} ${sharedClasses.smMt0} ${sharedClasses.smColSpan2} ${sharedClasses.smFlex} ${sharedClasses.smItemsCenter}`}
                        >
                          {formData?.username}
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className={`${sharedClasses.ml4}  cursor-pointer`}
                            onClick={handleEditName}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={`${sharedClasses.smFlex} ${sharedClasses.smSpaceX} ${sharedClasses.smItemsCenter}`}
                  >
                    <dt
                      className={`${sharedClasses.textSm} ${sharedClasses.fontMedium} ${sharedClasses.textZinc500} ${sharedClasses.smW40}`}
                    >
                      Phone Number
                    </dt>
                    {editPhonenumber ? (
                      <>
                        <input
                          type="text"
                          value={formData.phoneNumber}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              phoneNumber: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:border-blue-500"
                        />
                        <button
                          onClick={handleSavePhoneNumber}
                          className="ml-2 bg-blue-500 text-white px-3 py-1 rounded-md"
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className={` w-full justify-between px-5 ${sharedClasses.mt1} ${sharedClasses.textSm} ${sharedClasses.textZinc900} ${sharedClasses.smMt0} ${sharedClasses.smColSpan2} ${sharedClasses.smFlex} ${sharedClasses.smItemsCenter}`}
                        >
                          <p>{formData?.phoneNumber}</p>
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className={`${sharedClasses.ml4}   cursor-pointer`}
                            onClick={handleEditName}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={`${sharedClasses.smFlex} ${sharedClasses.smSpaceX} ${sharedClasses.smItemsCenter}`}
                  >
                    <dt
                      className={`${sharedClasses.textSm} ${sharedClasses.fontMedium} ${sharedClasses.textZinc500} ${sharedClasses.smW40}`}
                    >
                      Address
                    </dt>
                    {editAddress ? (
                      <>
                        <input
                          type="text"
                          value={formData.address}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              address: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:border-blue-500"
                        />
                        <button
                          onClick={handleSaveAddress}
                          className="ml-2 bg-blue-500 text-white px-3 py-1 rounded-md"
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className={` w-full justify-between px-5 ${sharedClasses.mt1} ${sharedClasses.textSm} ${sharedClasses.textZinc900} ${sharedClasses.smMt0} ${sharedClasses.smColSpan2} ${sharedClasses.smFlex} ${sharedClasses.smItemsCenter}`}
                        >
                          <p>{formData?.address}</p>
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className={`${sharedClasses.ml4}  items-end cursor-pointer`}
                            onClick={handleEditName}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={`${sharedClasses.smFlex} ${sharedClasses.smSpaceX} ${sharedClasses.smItemsCenter}`}
                  >
                    <dt
                      className={`${sharedClasses.textSm} ${sharedClasses.fontMedium} ${sharedClasses.textZinc500} ${sharedClasses.smW40}`}
                    >
                      Phone Number
                    </dt>
                    {editEmail ? (
                      <>
                        <input
                          type="text"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                          className="border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:border-blue-500"
                        />
                        <button
                          onClick={handleSaveEmail}
                          className="ml-2 bg-blue-500 text-white px-3 py-1 rounded-md"
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          className={` w-full justify-between px-5 ${sharedClasses.mt1} ${sharedClasses.textSm} ${sharedClasses.textZinc900} ${sharedClasses.smMt0} ${sharedClasses.smColSpan2} ${sharedClasses.smFlex} ${sharedClasses.smItemsCenter}`}
                        >
                          {formData?.email}
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className={`${sharedClasses.ml4} text-blue-500} cursor-pointer`}
                            onClick={handleEditName}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </dl>
              </div>

              <div className={sharedClasses.mt}>
                {/* <h3 className={`${sharedClasses.textLg} ${sharedClasses.leading6} ${sharedClasses.fontMedium} ${sharedClasses.textZinc900}`}>Your teams (2)</h3> */}
                <div className={sharedClasses.mt2}>
                  {/* Your Bank Details */}
                  <div className="my-4">
                    <h1 className="text-lg font-semibold text-gray-800">
                      Bank Details
                    </h1>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                      <div>
                        <h4 className="text-sm font-medium text-gray-600">
                          Account Holder Name:
                        </h4>
                        <p className="text-sm text-gray-900">
                          {bankDetails[0]?.accountHolderName}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-600">
                          Account Number:
                        </h4>
                        <p className="text-sm text-gray-900">
                          {bankDetails[0]?.accountNumber}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-600">
                          Bank Name:
                        </h4>
                        <p className="text-sm text-gray-900">
                          {bankDetails[0]?.bankName}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-600">
                          IFSC Code:
                        </h4>
                        <p className="text-sm text-gray-900">
                          {bankDetails[0]?.ifscCode}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-600">
                          Phone Number:
                        </h4>
                        <p className="text-sm text-gray-900">
                          {bankDetails[0]?.phoneNumber}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-600">
                          Settlement Terms:
                        </h4>
                        <p className="text-sm text-gray-900">
                          {bankDetails[0]?.settlementTerms}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${sharedClasses.flex} ${sharedClasses.justifyBetween} ${sharedClasses.itemsCenter} py-2`}
                  >
                    <div
                      className={`${sharedClasses.flex1} ${sharedClasses.minW0}`}
                    >
                      <h2
                        className={`${sharedClasses.textSm} ${sharedClasses.fontMedium} ${sharedClasses.textZinc900} truncate ${sharedClasses.cursorPointer}`}
                        onClick={toggleTeam2Data}
                      >
                        My Charger Details (Total No Of Chargers)
                      </h2>
                      <p
                        className={`${sharedClasses.textSm} ${
                          sharedClasses.textZinc500
                        } truncate ${
                          team2DataVisible ? "" : sharedClasses.hidden
                        }`}
                      >
                        {Source && Source.pids && Source.pids.length}
                      </p>
                    </div>
                    <div
                      className={`${sharedClasses.ml4} ${sharedClasses.flexShrink}`}
                    >
                      {/* <button className={`${sharedClasses.bgBlue500} ${sharedClasses.hoverBgBlue600} ${sharedClasses.textWhite} ${sharedClasses.fontBold} ${sharedClasses.py1} ${sharedClasses.px4} ${sharedClasses.rounded}`}>Create a Team</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default EditProfile;
