export function getUniqueDatesDashboard(arr) {
    const uniqueDates = {};
    const result = [];
  
    arr.forEach(item => {
        if(item.Date){
      const date = item.Date;
      if (!uniqueDates[date]) {
        uniqueDates[date] = true;
        result.push(date);
      }
    }
    });
  console.log(uniqueDates,"uniqueDates");
    return result;
  }


export  function formatDateDashboard2(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }
  
  function getDateRangeDashboard2(data) {
    let minDate = null;
    let maxDate = null;
  
    data.forEach(item => {
      const parts = item.Date.split("/");
      const formattedDate = new Date(`20${parts[2]}`, parts[1] - 1, parts[0]);
      if (!isNaN(formattedDate.getTime())) {
        if (!minDate || formattedDate < minDate) {
          minDate = formattedDate;
        }
        if (!maxDate || formattedDate > maxDate) {
          maxDate = formattedDate;
        }
      }
    });
  
    const datesArray = [];
    const currentDate = new Date(minDate);
    while (currentDate <= maxDate) {
      datesArray.push(formatDateDashboard2(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return datesArray;
  }
  
 
 
  
// dashboard dates function return range date array 
function formatDateDashboard(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
}

function isValidDate(dateString) {
  // Regular expression to match the "DD/MM/YY" format
  const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{2}$/;

  // Regular expression to match the optional leading zeros in day and month components
  const leadingZeroRegex = /^(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{2}$/;

  return dateRegex.test(dateString) || leadingZeroRegex.test(dateString);
}

export function getDateRangeDashboard(data) {
  let minDate = null;
  let maxDate = null;

  data.forEach(item => {
    if (item.Date && isValidDate(item.Date)) { // Check if the item has a Date key and it's in the valid format
      const parts = item.Date.split("/");
      const day = parts[0].padStart(2, '0'); // Pad day component with leading zero
      const month = parts[1].padStart(2, '0'); // Pad month component with leading zero
      const formattedDate = new Date(`20${parts[2]}`, month - 1, day);
      if (!isNaN(formattedDate.getTime())) {
        if (!minDate || formattedDate < minDate) {
          minDate = formattedDate;
        }
        if (!maxDate || formattedDate > maxDate) {
          maxDate = formattedDate;
        }
      }
    }
  });

  if (minDate && maxDate) { // Check if valid dates are found
    const datesArray = [];
    const currentDate = new Date(minDate);
    while (currentDate <= maxDate) {
      datesArray.push(formatDateDashboard(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return datesArray;
  } else {
    return [];
  }
}


// ylabel for dashboard

function isValidDate12(dateString) {
  // Regular expression to match the "DD/MM/YY" format
  const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{2}$/;

  // Regular expression to match the optional leading zeros in day and month components
  const leadingZeroRegex = /^(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[1-9]|1[0-2])\/\d{2}$/;

  return dateRegex.test(dateString) || leadingZeroRegex.test(dateString);
}

function getTotalEnergyUsedByDate(data) {
  const energyByDate = {};

  data.forEach(item => {
    if (item.Date && isValidDate12(item.Date)) {
      const parts = item.Date.split("/");
      const day = parts[0].padStart(2, '0');
      const month = parts[1].padStart(2, '0');
      const year = parts[2];
      const formattedDate = `${day}/${month}/${year}`;

      energyByDate[formattedDate] = (energyByDate[formattedDate] || 0) + parseInt(item.EnergyUsed || item.enegryused  ||0);
    }
  });

  const result = Object.keys(energyByDate).map(date => ({
    Date: date,
    TotalEnergyUsed: energyByDate[date]
  }));

  return result;
}



export function getEnergyUsedForDatesDashboard(data, dates) {
  data = getTotalEnergyUsedByDate(data);
  const energyUsedForDates = [];

  dates.forEach(date => {
    const found = data.find(item => item.Date === date);
    if (found) {
      energyUsedForDates.push(found.TotalEnergyUsed);
    } else {
      energyUsedForDates.push(0);
    }
  });

  return energyUsedForDates;
}

export  function getTotalTimeForEachDate(arrayA, arrayB) {
  // Check if arrayA is an array, if not, make it an empty array
  arrayA = Array.isArray(arrayA) ? arrayA : [];
  
  // Check if arrayB is an array, if not, make it an empty array
  arrayB = Array.isArray(arrayB) ? arrayB : [];

  const normalizeDate = (date) => {
    if (!date) return; // Return undefined if date is undefined
    const parts = date.split('/');
    if (parts.length !== 3) return; // Check if parts has day, month, and year
    const day = parts[0].padStart(2, '0');
    const month = parts[1].padStart(2, '0');
    const year = parts[2];
    return `${day}/${month}/${year}`;
  };

  const allDates = [...new Set([...arrayA, ...arrayB.map(item => normalizeDate(item.Date))])];
  const totalTimes = [];

  const maxLength = arrayA.length;

  allDates.slice(0, maxLength).forEach(date => {
    if (date) { // Check if date is defined
      const matchedEntries = arrayB.filter(entry => normalizeDate(entry.Date) === date && entry.endtime !== undefined && entry.endtime.toLowerCase() !== "" && entry.starttime.toLowerCase() !== "");
      if (matchedEntries.length > 0) {
        const totalTime = matchedEntries.reduce((total, entry) => {
          try {
            const startParts = entry.starttime.split(':').map(Number);
            const endParts = entry.endtime.split(':').map(Number);
            if (startParts.length !== 2 || endParts.length !== 2) {
              throw new Error('Invalid time format');
            }
            const [startHour, startMinute] = startParts;
            const [endHour, endMinute] = endParts;
            const startTime = startHour * 60 + startMinute;
            const endTime = endHour * 60 + endMinute;
            if (isNaN(startTime) || isNaN(endTime)) {
              throw new Error('Invalid time format');
            }
            // Check if end time is before start time, adjust accordingly
            const duration = endTime >= startTime ? (endTime - startTime) : (24 * 60 - startTime + endTime);
            return total + duration;
          } catch (error) {
            console.error(`Error processing entry for date ${date}: ${error.message}`);
            return total;
          }
        }, 0);
        totalTimes.push(totalTime);
      } else {
        totalTimes.push(0);
      }
    } else {
      totalTimes.push(0); // If date is undefined, push 0 to totalTimes array
    }
  });

  return totalTimes;
}



// get number for history point ke bad three number only
export function formatToThreeDecimalPlaces(number) {
  try {
    // Convert the input to a number if it's a string
    number = parseFloat(number);

    // Check for NaN or undefined
    if (isNaN(number) || number === undefined) {
      return "0.000";
    }

    const factor = Math.pow(10, 3);
    const result = Math.floor(number * factor) / factor;

    // Ensure the result has three decimal places
    return result.toFixed(3);
  } catch (error) {
    return "0.000";
  }
}