import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Circle from "./Circle";
import AddpidsModal from "./AddpidsModal";
import PlusContainer from "./PlusContainer/PlusContainer";
import Table from "../../genric/Table/Table";
import Sidebar from "../Sidebar/Sidebar";
import EditChargerModal from "../../EditChargerModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPids,
  getUserDetails,
  updateData,
} from "../../Redux/counterSlice";
import { editChargerName } from "../../Redux/counterSlice";
import mqtt from "mqtt";
import { useNavigate } from "react-router-dom";
import { DeletePid } from "../../Redux/counterSlice";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const AddProduct = () => {
  const [mqttClient, setMqttClient] = useState(null);
  const [status, setStatus] = useState("");
  const [DummyDataforTable, setDummyDataforTable] = useState([1, 2, 3, 4, 5]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const stored = localStorage.getItem("user");
    console.log("stored", stored);
    if (!stored) {
      navigate("/");
    }
  }, []);
  // const uid = "useSelector((state) => state?.Store)";

  const stored = localStorage.getItem("user");
  const storedUsername = JSON.parse(stored);
  const uid = storedUsername?.userData?.uid;
  console.log(storedUsername, "lllllll");
  console.log(uid, "kkkkkkkkk");
  const brokerUrl = "wss://mqtt.jouls.co.in/mqtt";
  const [CheckmqttMessagesReceived, setCheckmqttMessagesReceived] = useState(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const [showAddChargerModal, setShowAddChargerModal] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [rtl, setRtl] = useState(false);
  const [selectedCharger, setSelectedCharger] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const pidsData = useSelector((state) => state.Store.getAllPids);
  let AllPidDataWithMessages = useSelector(
    (state) => state.Store.allMqttMessagesPids
  );
  const populateBankDetails = false;
  const populateChargerHistoryData = false;
  const populatePidsDetails = true;

  useEffect(() => {
    const fetchdata = async () => {
      setIsLoading(true);
      await dispatch(
        getUserDetails({
          uid,
          populateBankDetails,
          populateChargerHistoryData,
          populatePidsDetails,
        })
      );
      setIsLoading(false);
      console.log("helolin usereff");
    };
    fetchdata();
  }, [dispatch, uid]);
  const [showEditModal, setShowEditModal] = useState(false);
  const handleConnect = () => {
    const client = mqtt.connect(brokerUrl, {
      rejectUnauthorized: true // Set to true if you're using a valid SSL certificate signed by a trusted CA
    });
    // Connection successful
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Subscribe to PID topic
      if (pidsData.length > 0) {
        pidsData.forEach((e) => {
          // const topics = [`${e.pid}_Updates`,`${e.pid}_radhe`];
          // const topics = [`${e.pid}_radhe`];
          const topics = [`${e.pid}_Updates`, `${e.pid}_Charging_Data`];
          topics.forEach((topic) => {
            client.subscribe(topic, (err) => {
              if (!err) {
                console.log("Subscribed to", topic);
              } else {
                console.error("Error subscribing to", topic, ":", err);
              }
            });
          });
        });
      } else {
        console.log("data nhi hai alldata me ",pidsData);
      }
      setMqttClient(client);
    });
  };

  const sendMessagesToTopics = () => {
    // MQTT client banaye
    const client = mqtt.connect(brokerUrl);

    // MQTT client connect hone ke baad
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Interval se messages send karein
      if (AllPidDataWithMessages.length > 0) {
        setInterval(() => {
          // Random message banaye
          const message = "IdleRadhe";
          // Har topic par message send karein
          AllPidDataWithMessages.forEach((topic) => {
            client.publish(`${topic.pid}_radhe`, message);
            console.log(`Message sent to topic ${topic}_radhe: ${message}`);
          });
        }, 1000); // Interval in milliseconds (2 seconds)
      } else {
        console.log("data nhi hai alldata me ");
      }
    });

    // MQTT client se disconnect hone ke baad
    client.on("offline", () => {
      console.log("Disconnected from MQTT broker");
    });

    // Cleanup function
    return () => {
      client.end(); // MQTT client ko cleanup karein
    };
  };
  useEffect(() => {
    // sendMessagesToTopics();
    handleConnect(); // Automatically connect on component mount
    if (!isLoading) {
      // Set showAddChargerModal based on pidsData length after loading is finished
      setShowAddChargerModal(pidsData.length === 0);
    }
  }, [pidsData]);

  console.log(pidsData, "pidsData", AllPidDataWithMessages);

  const applyMqttLogic = (topic, message) => {
    return AllPidDataWithMessages.map((obj) => {
      if (`${obj?.pid}_Charging_Data` === topic.toString()) {
        return { ...obj, status: "Charging Started" };
      }
      if (`${obj?.pid}_Updates` === topic.toString()) {
        // Handle logic for other topic
        if (message.toString() === "Idle") {
          return { ...obj, status: "Idle" };
        }
        if (message.toString() === "Door is open") {
          return { ...obj, status: "Charger Connected" };
        }
        if (message.toString() === "Charging Started") {
          return { ...obj, status: "Charging Started" };
        }
        if (message.toString() === "Charging Completed") {
          return { ...obj, status: "Charging Completed" };
        }
      } else {
        // For other objects, return as is
        return obj;
      }
    });
  };

  const handleMessageLogic = (topic, message) => {
    try {
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === "Charging_Data"
      ) {
        // If the topic is "Charging_Data" and the message is "charging_data", return without updating the state
        return;
      }
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === message.toString()
      ) {
        // If the topic and message exist, return without updating the state
        return;
      }

      let mqttUpdatedProducts;
      if (topic.includes("Charging_Data")) {
        mqttUpdatedProducts = applyMqttLogic(topic, message);
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(updateData(mqttUpdatedProducts));
      } else {
        mqttUpdatedProducts = applyMqttLogic(topic, message);
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(updateData(mqttUpdatedProducts));
      }

      const updatedMessage = topic.includes("Charging_Data")
        ? "Charging_Data"
        : message.toString();
      setCheckmqttMessagesReceived((prevMessages) => ({
        ...prevMessages,
        [topic]: updatedMessage,
      }));
    } catch (error) {
      console.error("Error parsing received message:", error);
    }
  };

  useEffect(() => {
    if (mqttClient) {
      mqttClient.removeAllListeners("message");
      mqttClient.on("message", handleMessageLogic);

      return () => {
        mqttClient.removeAllListeners("message");
      };
    }
  }, [mqttClient, AllPidDataWithMessages]);

  const handleEdit = (pid, charger) => {
    setSelectedCharger(charger);
    setShowModal1(true);
  };

  const handleDelete = (user) => {
    // Logic to delete the user
    console.log("Deleting user", user);

    // Dispatch the DeletePid action creator with the user's PID
    dispatch(DeletePid({ pid: user.pid }))
      .then(() => {
        // Reload the window after successful deletion
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        // Handle error appropriately (e.g., show error message)
      });
  };

  const handleSave = async (editedData) => {
    try {
      // Dispatch action to update charger name using editChargerName async thunk
      await dispatch(
        editChargerName({
          pid: editedData.pid,
          newChargerName: editedData.chargername, // Assuming editedData contains pid and chargername
        })
      );

      // Close the modal after saving
      setShowModal1(false);
    } catch (error) {
      console.error("Error updating charger name:", error);
      // Handle error appropriately (e.g., show error message)
    }
  };

  useEffect(() => {
    const applyApiLogic = (apiProducts) => {
      // Apply your logic here (for example, filtering or modifying products)
      let OrderPIds = [];
      for (let i = 0; i < apiProducts.length; i++) {
        // OrderPIds.push(userData[i].products[j].OrderPids[k])
        OrderPIds.push({
          pid: apiProducts[i].pid,
          message: "",
          chargername: apiProducts[i].ChargerName,
        });
        // setalldata(OrderPIds)
      }
      return OrderPIds;
    };

    // Apply API logic on products from the API
    const apiUpdatedProducts = applyApiLogic(pidsData);

    // Dispatch the updated products to the Redux store
    dispatch(updateData(apiUpdatedProducts));
  }, [pidsData, dispatch]);

  useEffect(() => {
    const data = [{ serialno: 1 }, { serialno: 2 }, { serialno: 3 }];
    setDummyDataforTable(data);
  }, []);

  const columns = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "Charger ID",
      accessor: "chargerid",
    },
    {
      header: "Current status",
      accessor: "chargerstatus",
    },
    {
      header: "remarks",
      accessor: "message",
    },
    {
      header: "Action",
      accessor: "actions",
    },
  ];

  let count = 1;
  const memberData = [];
  const data = AllPidDataWithMessages.map((user) => ({
    serialno: count++,
    chargername: user?.chargername || "",
    chargerid: user?.pid,
    chargerstatus: user?.status,
    actions: (
      <div>
        <OverlayTrigger
          key={"edit-tooltip-" + user.pid}
          placement="top"
          overlay={
            <Tooltip id={"tooltip-edit-" + user.pid}>Edit Charger</Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => handleEdit(user.pid, user)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
        </OverlayTrigger>
        <OverlayTrigger
          key={"delete-tooltip-" + user.pid}
          placement="top"
          overlay={
            <Tooltip id={"tooltip-delete-" + user.pid}>Delete Charger</Tooltip>
          }
        >
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => handleDelete(user)}
            style={{ cursor: "pointer" }}
          />
        </OverlayTrigger>
      </div>
    ),
  }));

  const clickaddcharger = () => {
    setShowModal(true);
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar collapsed={collapsed} rtl={rtl} />

      <main className="w-full py-8 px-4 lg:ml-20 mt-12 overflow-hidden">
        <div className="w-full mb-8 text-lg md:text-xl lg:text-3xl font-semibold text-[#605C5C] capitalize ">
          Add Charger
        </div>

        <div className="w-full">
          <div className="mt-4">
            <div className="">
              <main>
                <div
                  className="w-full"
                  // style={{ maxWidth: "100%", maxHeight: "300px" }}
                >
                  {showAddChargerModal ? (
                    <div className="w-full">
                      {/* Pass handleShow function to PlusContainer */}
                      <PlusContainer handleShow={handleShow} />
                      <AddpidsModal
                        showModal={showModal}
                        handleClose={handleClose}
                      />
                    </div>
                  ) : (
                    <div className="">
                      {data && data.length > 0 ? (
                        <Table
                          greenClicked={clickaddcharger}
                          columns={columns}
                          data={data}
                          pageSize={10}
                          greenButtonText={"Add Charger"}
                          onEdit={handleEdit}
                        />
                      ) : (
                        <>
                          <Table
                            greenClicked={clickaddcharger}
                            columns={columns}
                            data={DummyDataforTable}
                            pageSize={10}
                            greenButtonText={"Add Charger"}
                            onEdit={handleEdit}
                          />
                        </>
                      )}
                    </div>
                  )}
                  <div className="flex">
                    <EditChargerModal
                      showModal1={showModal1}
                      handleClose={() => setShowModal1(false)}
                      chargerName={
                        selectedCharger ? selectedCharger.chargername : ""
                      }
                      pid={selectedCharger ? selectedCharger.pid : ""} // Pass the pid
                      onSave={handleSave}
                    />
                    <AddpidsModal
                      showModal={showModal}
                      handleClose={handleClose}
                    />
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddProduct;
