import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Chart from "../History/chart";
import LineChart from "../History/linechart";
import PieChart from "../History/PieChart";
import ContainerWithBorder from "./Container";
import Cardrevnue from "./Cardrevnue";
import Table from "../../genric/Table/Table";
import BankDetailsModal from "./RevenueManagementModal";
// import Revenue from './Revenue';
import {
  DashboardUpdateData,
  RevenueXlabelUpdateData,
  RevenueYlabelUpdateData,
  UpdateCost,
  YlabelUpdateData,
  getAllChargerHistory,
  getAllPids,
  getUserDetails,
} from "../../Redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RevenueXlabel } from "../../genric/xlabelforRevenue/RevenueXlabel";
import { calculateTotalAmountByDate } from "../../genric/ylabelforRevenue/ylabelForRevenue";
import { useNavigate } from "react-router-dom";
import {
  calculateAmountSum,
  calculateEnergySum,
  calculateTimeDifference,
  calculateTimeDifferenceStartTimeEndTime,
} from "../../genric/getTotalEneryAndTotalRevenue/TotalEnergyRevenu";
import mqtt from "mqtt";
import SupportChatModal1 from "./SupportChatModal1";
import { formatToThreeDecimalPlaces } from "../../genric/labelforDashboard/label";
// import SupportChatModal from "../Support/SupportChatModal";

const RevenueManagement = () => {
  const brokerUrl = "wss://mqtt.jouls.co.in/mqtt";
  // const brokerUrl = "34.47.145.120";
  const navigate = useNavigate();
  useEffect(() => {
    const stored = localStorage.getItem("user");
    // console.log("stored", stored);
    if (!stored) {
      navigate("/");
    }
  }, []);
  const stored = localStorage.getItem("user");
  const storedUsername = JSON.parse(stored);
  const uid = storedUsername?.userData?.uid;
  // console.log(storedUsername, "lllllll");
  // console.log(uid, "kkkkkkkkk");
  const [CheckmqttMessagesReceived, setCheckmqttMessagesReceived] = useState(
    {}
  );
  console.log("CheckmqttMessagesReceived", CheckmqttMessagesReceived);
  const [ShowTotalRevenue, setShowTotalRevenue] = useState(0);
  const [ShowTotalEnergy, setShowTotalEnergy] = useState(0);
  const [ShowTotalUtilization, setShowTotalUtilization] = useState(0);
  const [ShowActiveDevices, setShowActiveDevices] = useState(0);
  const [ShowIdleDevices, setShowIdleDevices] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const [DummyDataforTable, setDummyDataforTable] = useState([1, 2, 3, 4, 5]);
  const [mqttClient, setMqttClient] = useState(null);
  const [rtl, setRtl] = useState(false);
  const [ylabeldata, setylabeldata] = useState([]);
  const [chargingCost, setchargingCost] = useState("");
  const [TotalCharges, setTotalCharges] = useState("0");
  const [ChargigCostkwh, setChargigCostkwh] = useState("0");
  const [ChargigCosthwr, setChargigCosthwr] = useState("0");

  // console.log(chargingCost, "chargingCost");
  const [bankDetails, setBankDetails] = useState(null);
  console.log(ShowIdleDevices, "bannnnnkkkkkkkkkkkkkk");
  const dispatch = useDispatch();
  const pidsData = useSelector((state) => state.Store.getAllPids);
  const revenueXlabelData = useSelector((state) => state.Store.revenueXlabel);
  const revenueYlabelData = useSelector((state) => state.Store.revenueYlabel);
  const getAllChargerHistoryData = useSelector(
    (state) => state.Store.getAllChargerHistory
  );
  let AllPidDataWithMessages = useSelector(
    (state) => state.Store.allDashboardPidsMessages
  );
  console.log("getAllChargerHistoryData", getAllChargerHistoryData);
  console.log("AllPidDataWithMessages", AllPidDataWithMessages);
  console.log("pidsData", pidsData);
  const populateBankDetails = false;
  const populateChargerHistoryData = true;
  const populatePidsDetails = true;
  useEffect(() => {
    const fetchdata = async () => {
      await dispatch(
        getUserDetails({
          uid,
          populateBankDetails,
          populateChargerHistoryData,
          populatePidsDetails,
        })
      );
      // await dispatch(getAllChargerHistory(uid));
    };
    fetchdata();
  }, [dispatch]);

  useEffect(() => {
    const data = calculateEnergySum(getAllChargerHistoryData);
    const dataUtiliazation = calculateTimeDifference(getAllChargerHistoryData);
    const dataRevenue = calculateAmountSum(getAllChargerHistoryData);
    console.log("pidsdatacharget", getAllChargerHistoryData);
    const fetchdata = async () => {
      const a = RevenueXlabel(getAllChargerHistoryData);
      const b = calculateTotalAmountByDate(getAllChargerHistoryData);
      console.log("ainusereffect", a, b);
      await dispatch(RevenueXlabelUpdateData(a));
      await dispatch(RevenueYlabelUpdateData(b));
      const ylabelData = getMatchingAmounts(a, b);
      setylabeldata(ylabelData);
    };
    fetchdata();
    setShowTotalRevenue(dataRevenue);
    setShowTotalEnergy(data);
    setShowTotalUtilization(dataUtiliazation);
  }, [getAllChargerHistoryData]);

  const [childinputValues, setChildinputValues] = useState({
    ChargingCost: "0",
    chargingCostPerHour: "0",
    maintenanceCharges: "0",
  });
  console.log("childinputValues", childinputValues);
  const applyApiLogic = (apiProducts) => {
    // Apply your logic here (for example, filtering or modifying products)
    return apiProducts.map((product) => ({
      pid: product?.pid,
      remark: "",
      status: "",
      chargername: product?.ChargerName,
    }));
  };

  const updateChildInputValuesAndHandleConnect = () => {
    // Update child input values
    setChildinputValues({
      ...childinputValues,
      ChargingCost: pidsData[0]?.chargingCost,
      chargingCostPerHour: pidsData[0]?.chargingCostPerHour,
      maintenanceCharges: pidsData[0]?.maintenanceCharges,
    });
    // Handle connection
    handleConnect();
  };

  useEffect(() => {
    if (!Array.isArray(pidsData)) {
      console.error("pidsData is not an array");
      return;
    }

    // Update child input values and handle connection
    updateChildInputValuesAndHandleConnect();

    // Apply API logic and dispatch updated products
    const apiUpdatedProducts = applyApiLogic(pidsData);
    console.log("apiUpdatedProducts", apiUpdatedProducts);
    dispatch(DashboardUpdateData(apiUpdatedProducts));
  }, [pidsData, dispatch]);

  const applyMqttLogic = (topic, message) => {
    return AllPidDataWithMessages.map((obj) => {
      if (`${obj?.pid}_Charging_Data` === topic.toString()) {
        return { ...obj, status: "Charging Started" };
      }
      if (`${obj?.pid}_Updates` === topic.toString()) {
        // Handle logic for other topic
        if (message.toString() === "Idle") {
          return { ...obj, status: "Idle" };
        }
        if (message.toString() === "Door is open") {
          return { ...obj, status: "Charger Connected" };
        }
        if (message.toString() === "Charging Started") {
          return { ...obj, status: "Charging Started" };
        }
        if (message.toString() === "Charging Completed") {
          return { ...obj, status: "Charging Completed" };
        }
      } else {
        // For other objects, return as is
        return obj;
      }
    });
  };

  const handleMessageLogic = (topic, message) => {
    try {
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === "Charging_Data"
      ) {
        // If the topic is "Charging_Data" and the message is "charging_data", return without updating the state
        return;
      }
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === message.toString()
      ) {
        // If the topic and message exist, return without updating the state
        return;
      }
      let mqttUpdatedProducts;
      if (topic.includes("Charging_Data")) {
        mqttUpdatedProducts = applyMqttLogic(topic, message);
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(DashboardUpdateData(mqttUpdatedProducts));
      } else {
        mqttUpdatedProducts = applyMqttLogic(topic, message);
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(DashboardUpdateData(mqttUpdatedProducts));
      }
      const updatedMessage = topic.includes("Charging_Data")
        ? "Charging_Data"
        : message.toString();
      setCheckmqttMessagesReceived((prevMessages) => ({
        ...prevMessages,
        [topic]: updatedMessage,
      }));
    } catch (error) {
      console.error("Error parsing received message:", error);
    }
  };

  useEffect(() => {
    if (mqttClient) {
      mqttClient.removeAllListeners("message");
      mqttClient.on("message", handleMessageLogic);

      return () => {
        mqttClient.removeAllListeners("message");
      };
    }
  }, [mqttClient, AllPidDataWithMessages]);

  // useEffect(() => {

  //   const a = getMatchingAmounts(revenueXlabelData,)
  //   setylabeldata(a);
  // }, [revenueYlabelData]);

  const getMatchingAmounts = (a, b) => {
    // Create a new array to store amounts
    const amounts = [];

    // Iterate over each element of array 'a'
    a.forEach((valueA) => {
      // Find the corresponding object in array 'b' where 'date' matches 'valueA'
      const matchingObject = b.find((obj) => obj.date === valueA);

      // If a matching object is found, push its amount to the 'amounts' array
      if (matchingObject) {
        amounts.push(matchingObject.amount);
      } else {
        // If no matching object is found, push 'null' to the 'amounts' array
        amounts.push(null);
      }
    });

    // Return the resulting array of amounts
    return amounts;
  };

  useEffect(() => {
    const data = [{ serialno: 1 }, { serialno: 2 }, { serialno: 3 }];
    setDummyDataforTable(data);
  }, []);

  const columns = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Payment ID",
      accessor: "paymentid",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "User Name",
      accessor: "username",
    },
    {
      header: "Input Amount",
      accessor: "inputamount",
      unit: "₹",
    },
    {
      header: "Charging Time",
      accessor: "chargingtime",
    },
    {
      header: " Used Charging Cost",
      accessor: "chargingCost",
      unit: "₹",
    },
  ];

  let count = 1;
  const memberData = [];
  let data = getAllChargerHistoryData?.map((user, index) => ({
    serialno: getAllChargerHistoryData?.length - index,
    date: user?.Date,
    paymentid: user?.paymentId,
    username: user?.UsedBy,
    inputamount: user?.inputCost,
    chargername: user?.ChargerName,
    // chargingtime: user.time,
    chargingtime: calculateTimeDifferenceStartTimeEndTime(
      user?.StartTime,
      user?.EndTime
    ),
    chargingCost: formatToThreeDecimalPlaces(user?.chargingCost),
  }));
  data = data.reverse();
  // console.log("datainrevenue", data);
  const clickaddcharge = () => {
    // console.log("click hua add charger in revenuemagages");
    navigate("/addcharger");
  };

  const [inputValues, setInputValues] = useState({
    "rates Per Kwh": 0,
    "Per hour": 0,
    "maintenance charges": 0,
  });

  // console.log("childinpuvalues", childinputValues);

  const handleChildValueChange = (childKey, value) => {
    setChildinputValues((prevState) => ({
      ...prevState,
      [childKey]: value,
    }));
  };

  // Function to handle updates from Cardrevnue components
  const handleUpdate = (label, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [label]: value,
    }));
  };
  // const populateBankDetails=true;
  const url = "https://backend.jouls.co.in";
  const handleValueChange1 = (newValue) => {
    // console.log("inputgjhg");
    setchargingCost(newValue);
  };

  const buttonText = bankDetails
    ? `${
        bankDetails[0]?.bankName
      }  XXXX-XXXX-XXXX-${bankDetails[0]?.accountNumber.slice(-4)}`
    : "Enter Your Bank Details";

  // Calculate total charges based on the input values
  const totalCharges =
    inputValues["rates Per Kwh"] +
    inputValues["Per hour"] +
    inputValues["maintenance charges"];

  const handleButtonClick = (childNumber) => {
    console.log(`Button ${childNumber} clicked`);
    if (childNumber === 1) {
      // Kaam 1
      if (childinputValues.ChargingCost) {
        const formData = { ChargingCost: childinputValues.ChargingCost };
        dispatch(UpdateCost({ formData, uid }));
      }
      // console.log("Doing task for child 1...");
    } else if (childNumber === 2) {
      // Kaam 2
      if (childinputValues.chargingCostPerHour) {
        const formData = {
          chargingCostPerHour: childinputValues.chargingCostPerHour,
        };
        dispatch(UpdateCost({ formData, uid }));
      }
      // console.log("Doing task for child 2...");
    } else if (childNumber === 3) {
      // Kaam 3
      if (childinputValues.maintenanceCharges) {
        const formData = {
          maintenanceCharges: childinputValues.maintenanceCharges,
        };
        dispatch(UpdateCost({ formData, uid }));
      }
      // console.log("Doing task for child 3...");
    }
    // Yahan pe aap jo bhi kaam karna chahte hain, woh karein
  };

  // total charges useeffect

  // apply mqtt
  const handleConnect = () => {
    // const connectOptions = {
    //   clean: true, // Clean session
    //   connectTimeout: 4000, // Timeout period
    //   reconnectPeriod: 1000, // Reconnect period
    // };
    const client = mqtt.connect(brokerUrl);
    // Connection successful
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Subscribe to PID topic
      if (pidsData.length > 0) {
        console.log("in if revenuemangeagemn ", pidsData);
        pidsData.forEach((e) => {
          const topics = [`${e.pid}_Updates`, `${e.pid}_Charging_Data`];
          topics.forEach((topic) => {
            client.subscribe(topic, (err) => {
              if (!err) {
                console.log("Subscribed to", topic);
              } else {
                console.error("Error subscribing to", topic, ":", err);
              }
            });
          });
        });
      } else {
        console.log("data nhi hai alldata me ", pidsData);
      }
      setMqttClient(client);
    });
  };

  useEffect(() => {
    const chargingCost = parseInt(childinputValues.ChargingCost) || 0;
    const chargingCostPerHour =
      parseInt(childinputValues.chargingCostPerHour) || 0;
    const maintenanceCharges =
      parseInt(childinputValues.maintenanceCharges) || 0;
    // console.log("chargingCostPerHourchargingCostPerHour", chargingCostPerHour);
    if (!isNaN(chargingCost) && !isNaN(chargingCostPerHour)) {
      const totalCharges =
        chargingCost + chargingCostPerHour + maintenanceCharges;
      // console.log("total charges", totalCharges);

      setChargigCostkwh(totalCharges - chargingCostPerHour);
      setChargigCosthwr(totalCharges - chargingCost);
      setTotalCharges(totalCharges);
    }
  }, [childinputValues]);
  //
  console.log(uid, "uiiiiiiiiiiiiiiiddddddddddddddddddd");
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await fetch(`${url}/user/UserPanelRoutes/${uid}?populateBankDetails=${populateBankDetails}`);
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch user data");
  //       }
  //       const userData = await response.json();
  //       console.log("Fetched user data:", userData); // Logging userData

  //       // Check if bank details exist
  //       if (userData.bankDetails) {
  //         setBankDetails(userData.bankDetails); // Set bank details to state
  //       } else {
  //         setBankDetails(null); // Reset bank details state
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user data:", error.message);
  //     }
  //   };

  //   fetchUserData();
  // }, [uid, populateBankDetails]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick1 = () => {
    // console.log("Button clicked"); // Add this line to check if the button click event is triggered
    setIsModalOpen(true);
    console.log("Modal state:", isModalOpen); // Add this line to check the modal state after setting it
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // console.log(buttonText,"muskan");

  useEffect(() => {
    if (!Array.isArray(AllPidDataWithMessages)) {
      console.error("AllPidDataWithMessages is not an array");
      return;
    }
    const filterData = AllPidDataWithMessages.filter(
      (e) => e && e.status && e.status.toLowerCase() == "idle"
    );

    const filterData2 = AllPidDataWithMessages.filter(
      (e) =>
        e && // Check if e exists
        e.status && // Check if e.status exists
        (e.status.toLowerCase() === "charging started" ||
          e.status.toLowerCase() === "charger connected" ||
          e.status.toLowerCase() === "charging completed")
    );
    console.log(filterData2, "filterData2");
    if (filterData.length !== ShowIdleDevices) {
      setShowIdleDevices(filterData.length);
    }
    // Check if the length of filterData2 has changed before updating state
    if (filterData2.length !== ShowActiveDevices) {
      setShowActiveDevices(filterData2.length);
    }
  }, [AllPidDataWithMessages]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar collapsed={collapsed} rtl={rtl} />
      <main className="w-full py-8 px-4 lg:ml-20 mt-12 overflow-hidden">
        <div className="flex justify-between items-center ">
          <div className="text-lg md:text-xl lg:text-3xl font-semibold text-[#605C5C] capitalize ">
            Revenue Managemant
          </div>

          <div>
            <button
              className="text-xs md:text-lg"
              style={{
                backgroundColor: "green",
                color: "white",
                borderRadius: "8px",
              }}
              onClick={handleButtonClick1}
            >
              {buttonText}
            </button>
          </div>
        </div>
        <div className="my-4">
          <div className="flex justify-between flex-wrap">
            <Cardrevnue
              childNumber={1}
              onButtonClick={handleButtonClick}
              title={"per kwh"}
              label={"rates Per Kwh"}
              onUpdate={handleUpdate}
              Hovering={true}
              // onValueChange={handleValueChange1}
              initialValue={childinputValues.ChargingCost}
              onValueChange={(value) =>
                handleChildValueChange("ChargingCost", value)
              }
            />
            <Cardrevnue
              childNumber={2}
              onButtonClick={handleButtonClick}
              title={"per Hour"}
              label={"Per hour"}
              onUpdate={handleUpdate}
              Hovering={true}
              initialValue={childinputValues.chargingCostPerHour}
              onValueChange={(value) =>
                handleChildValueChange("chargingCostPerHour", value)
              }
            />
            <Cardrevnue
              childNumber={3}
              onButtonClick={handleButtonClick}
              title={"Maintenance Charges"}
              label={"maintenance charges"}
              onUpdate={handleUpdate}
              Hovering={true}
              initialValue={childinputValues.maintenanceCharges}
              onValueChange={(value) =>
                handleChildValueChange("maintenanceCharges", value)
              }
            />
            <Cardrevnue
              initialValue={TotalCharges || "0"}
              title={"Total Charges"}
              ChargigCostkwh={ChargigCostkwh || "0"}
              ChargigCosthwr={ChargigCosthwr || "0"}
              totalCharges={totalCharges}
              Hovering={false}
            />
          </div>
          <div className="flex justify-center bg-white shadow-md border rounded-xl mt-10 p-2">
            <h4>Charging Report</h4>
          </div>
          <div className="xl:flex w-full mt-8 gap-1 p-2">
            <div className="w-full xl:w-1/2 p-4">
              {/* <div className="flex font-semibold text-xl">Cumulative Usage</div> */}
              {/* <LineChart /> */}
              <h2>Bar Chart</h2>
              <Chart
                xLabelData={revenueXlabelData}
                ylabelData={ylabeldata}
                ylabelName={"(₹) Rupees"}
                topName={""}
                xlabelName={"Date"}
              />
            </div>
            <div className="w-full xl:w-1/2 ">
              <ContainerWithBorder
                ShowTotalRevenue={ShowTotalRevenue}
                ShowTotalEnergy={ShowTotalEnergy}
                ShowTotalUtilization={ShowTotalUtilization}
                ShowIdleDevices={ShowIdleDevices}
                ShowActiveDevices={ShowActiveDevices}
              />
            </div>
          </div>

          {data && data.length > 0 ? (
            <Table
              greenClicked={clickaddcharge}
              columns={columns}
              data={data}
              pageSize={10}
              // greenButtonText={"Add Charger "}
            />
          ) : (
            <>
              <Table
                greenClicked={clickaddcharge}
                columns={columns}
                data={DummyDataforTable}
                pageSize={10}
              />
            </>
          )}
        </div>
        {/* {isModalOpen && ( */}
        <SupportChatModal1
          show={isModalOpen}
          onClose={closeModal}
          uid={uid}
          // bankDetails1={bankDetails}
        />

        {/* )} */}
      </main>
    </div>
  );
};

export default RevenueManagement;
