import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Nav_bar from "./Component/Navbar/Nav_bar";
import Charger from "./Charger";
import Dashboard from "./Component/Dashboard/Dashboard";
import History from "./Component/History/History";
import New_register from "./Component/Register/New_register";
import New_login from "./Component/Login/New_Login";
import AddProduct from "./Component/AddProduct/AddProduct";
import Support from "./Component/Support/Support";
import Revnuemanagemant from "./Component/Revnue Managemant/Revnuemanagemant";
import EditProfile from "./Pages/editprofile";
import Startscreen from "./Component/StartScreen/Startscreen";
import Chat from './Component/Chat'

const AppContent = () => {
  const theme = createTheme({
    palette: {
      themeColor: {
        main: "#c93a0e",
      },
    },components: {
      MuiSnackbar: {
        variants: [
          {
            props: { variant: "trouble" },
            style: {
              "& .MuiSnackbarContent-root": {
                background: "green"
              }
            }
          },
          {
            props: { variant: "bigTrouble" },
            style: {
              "& .MuiSnackbarContent-root": {
                background: "red"
              }
            }
          }
        ]
      }
    }
  });

  // Check if the user is authenticated
  const isAuthenticated = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))?.userData?.token
    : false;

  const location = useLocation();
  const pathname = location.pathname;

  console.log(isAuthenticated, "klklklk");

  return (
    <ThemeProvider theme={theme}>
      {pathname !== "/newlogin" &&
        pathname !== "/newregister" &&
        pathname !== "/" && <Nav_bar />}
      <Routes>
        {/* {isAuthenticated ? (
          <Route path="/dashboard" element={<Dashboard />} />
        ) : (
          <Route path="/" element={<New_login />} />
        )} */}

        <Route path="/" element={<Startscreen />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/charger" element={<Charger />} />
        <Route path="/history" element={<History />} />
        <Route path="/addcharger" element={<AddProduct />} />
        <Route path="/Support" element={<Support />} />
        <Route path="/RevenueManagemant" element={<Revnuemanagemant />} />
        <Route path="/newlogin" element={<New_login />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/newregister" element={<New_register />} />
        {/* <Route path="/chat" element={<Chat />} /> */}

      </Routes>
    </ThemeProvider>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;

