import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextInputs from "./TextInputs";
import CircleComponent from "./CircleComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import abc from "./abc";

import OtpInput from "otp-input-react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { createUser } from "../../Redux/counterSlice";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ErrorToast from "../Toast/ErrorToast"; // Import ErrorToast
import VerificationToast from "./verify";
import { auth } from "./otp";

const New_register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Define state setters for error message
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [passVisibility, setpassVisibility] = useState("password");
  const [click, setclick] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);
  const [otpField, setOtpField] = useState(false);
  const [otp1, setotp1] = useState("");
  // const [timer, setTimer] = useState(30);
  // const navigate = useNavigate();

  // const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    mobileNo: "",
    businessName: "",
    businessAddress: "",
    password: "",
    confirmPassword: "",
  });
  console.log(formData?.mobileNo, "dsdsdsds");

  function handleClick2() {
    navigate("/login");
  }

  const handleInputchange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  // useEffect(() => {
  //   if (!phoneVerified) {
  //     const reloadTimer = setTimeout(() => {
  //       window.location.reload();
  //     }, 60000); // 1 minute in milliseconds

  //     return () => clearTimeout(reloadTimer);
  //   }
  // }, [phoneVerified]);
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      return (window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        }
      ));
    }
  }

  function onSignup() {
    setLoading(true);

    const appVerifier = onCaptchVerify();
    console.log(appVerifier, "kkjdsnkjsn");
    const formatPh = "+91" + formData?.mobileNo;
    console.log(formatPh, "dsjdsjb");

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        console.log(confirmationResult, "skjsddkn");
        // setToastMessage("OTP sent!");
        // toast.success("OTP sent!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  const handleVerifyPhone = () => {
    // Implement phone number verification logic here (e.g., send OTP)
    // Simulating OTP verification success after 5 seconds
    setTimeout(() => {
      setPhoneVerified(true);
      setToastMessage("Phone number verified successfully.");
      setShowErrorToast(true);
    }, 5000);
    setTimer(60); // Set timer for resend
  };
  const handleEditNumber = () => {
    // Implement resend OTP logic here
    setShowOTP(false);
    setTimer(60); // Reset timer
    // Simulating OTP resend success after 5 seconds
  };
  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };
  const handleSignupClick = async () => {
    // Check if any field is empty
    const requiredFields = [
      "name",
      "username",
      "email",
      "mobileNo",
      "businessName",
      "businessAddress",
      "password",
      "confirmPassword",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      // If any field is empty, show error message
      setToastMessage("All fields are required.");
      setShowErrorToast(true);
      return;
    }

    // if (!phoneVerified) {
    //   // If phone number is not verified, display error message
    //   setToastMessage("Phone number must be verified before signing up.");
    //   setShowErrorToast(true);
    //   return;
    // }

    // Check if password and confirm password match
    if (formData.password !== formData.confirmPassword) {
      setToastMessage("Passwords do not match.");
      setShowErrorToast(true);
      return;
    }

    try {
      await dispatch(createUser(formData));
      console.log("User created successfully");
      // Reset form data after successful signup
      setFormData({
        name: "",
        username: "",
        email: "",
        mobileNo: "",
        businessName: "",
        businessAddress: "",
        password: "",
        confirmPassword: "",
      });
      // Redirect to login page
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating user:", error.message);
    }
  };

  // const handleVerifyPhone = () => {
  //     // Implement phone number verification logic here (e.g., send OTP)
  //     // After successful verification, update phoneVerified state
  //     navigate('/abc')
  //     setPhoneVerified(true);
  //     setToastMessage("Phone number verified successfully.");
  //     setShowErrorToast(true);
  //   };
  // const handleSignupClick = async () => {
  //   try {
  //     await dispatch(createUser(formData));
  //     console.log("User created successfully");
  //     // localStorage.setItem("username", formData.username);
  //     // Reset form data after successful signup
  //     setFormData({
  //       name: "",
  //       username: "",
  //       email: "",
  //       mobileNo: "",
  //       businessName: "",
  //       businessAddress: "",
  //       password: "",
  //       confirmPassword: "",
  //     });
  //     // Redirect to login page
  //     navigate("/dashboard");
  //   } catch (error) {
  //     console.error("Error creating user:", error.message);
  //   }
  // };

  function onOTPVerify() {
    setShowOTP(false);
    setotp1(true);
    setPhoneVerified(true);
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        setLoading(false);
        // localStorage.setItem("user", "data1");
        // navigate("/dashboard", { state: ph });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setToastMessage("Wrong Otp");
        setShowErrorToast(true);
        setOtp("");
      });
  }

  return (
    <>
      <div id="recaptcha-container"></div>
      {/* !showOTP */}
      {!showOTP ? (
        <div className="flex flex-col justify-center items-center w-full h-screen ">
          <div className="flex w-2/3 border border-[#CACACA] rounded-xl overflow-hidden ">
            <div className="w-1/2 relative overflow-hidden bg-[#C1E0C2] xl:block hidden">
              <div className="absolute top-5 left-5 z-20 text-xl text-[#118615] font-bold">
                Welcome!
              </div>
              <div className="absolute flex flex-col justify-center items-center w-full h-full ">
                <div className="w-44 h-20 rounded-xl bg-white z-30">
                  <img className="cover" src="/images/logoj.png" alt="logo" />
                </div>
                <h1 className="text-xs text-[#5E5E5E] mt-2">
                  Energizing Greener Future
                </h1>
              </div>
              <div className="absolute top-0 bottom-0 flex -ml-28 items-center">
                <CircleComponent />
              </div>
              <div className="absolute h-full right-0 flex flex-col  justify-between -mr-24">
                <div className="-rotate-45 -mt-28 ">
                  <CircleComponent mw={20} />
                </div>
                <div className="rotate-45 -mb-28">
                  <CircleComponent />
                </div>
              </div>
            </div>
            {/* <--------------form/------------------------> */}

            <div className="flex justify-center items-center py-5 w-full xl:w-1/2   ">
              <div className="p-3  ">
                <div className="flex items-center justify-center mx-16">
                  <div className="w-full border-b border-[#CACACA]"></div>
                  <span className="text-lg text-[#6B6A6A] mx-5 font-bold">
                    Signup
                  </span>
                  <div className="w-full border-b border-[#CACACA]"></div>
                </div>

                <div className="w-auto ">
                  <div className="my-8 ">
                    <div className="md:flex gap-5 my-8 md:my-8">
                      <div className="w-full mb-8 md:mb-0">
                        <TextInputs
                          // type={"text"}
                          title={"Name"}
                          name="name"
                          value={formData.name}
                          placeholder="Enter your name"
                          onInputChange={(value) =>
                            handleInputchange("name", value)
                          }
                          // onInputChange={(value)=()=>handleInputchange("name",value)
                          // }
                        />
                      </div>
                      <div className="w-full ">
                        <TextInputs
                          type={"text"}
                          title={"Username "}
                          name="username"
                          value={formData.username}
                          placeholder="Enter your username"
                          onInputChange={(value) =>
                            handleInputchange("username", value)
                          }
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-5 my-8 md:my-8">
                      <div
                        className="w-full mb-8 md:mb-0"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <TextInputs
                          type={"text"}
                          title={"Contact"}
                          name="contact"
                          value={formData.mobileNo}
                          placeholder="Enter your mobile number"
                          onInputChange={(value) =>
                            handleInputchange("mobileNo", value)
                          }
                        />
                        {/* <button style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer', direction: 'rtl' }} onClick={onSignup}>
    Verify
  </button> */}
                      </div>

                      <div className="w-full ">
                        <TextInputs
                          type={"text"}
                          title={"Email"}
                          name="email"
                          value={formData.email}
                          placeholder="Enter your username"
                          onInputChange={(value) =>
                            handleInputchange("email", value)
                          }
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-5 my-8 md:my-8">
                      <div className="w-full mb-8 md:mb-0">
                        <TextInputs
                          type={"text"}
                          title={"Buissness name"}
                          name="buissness name"
                          value={formData.businessName}
                          placeholder="Enter your Buissnes name "
                          onInputChange={(value) =>
                            handleInputchange("businessName", value)
                          }
                        />
                      </div>
                      <div className="w-full ">
                        <TextInputs
                          type={"text"}
                          title={"Buissness address"}
                          name="buissness address"
                          value={formData.businessAddress}
                          placeholder="Enter your buissness address"
                          onInputChange={(value) =>
                            handleInputchange("businessAddress", value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex gap-5 my-8 md:my-8">
                    <div className="w-full mb-8 md:mb-0 flex items-center">
                      <TextInputs
                        type={passVisibility}
                        title={"Password"}
                        name="password"
                        value={formData.password}
                        placeholder="Enter your password"
                        onInputChange={(value) =>
                          handleInputchange("password", value)
                        }
                      />
                      <div className="-ml-8 z-2 cursor-pointer">
                        {passVisibility === "password" ? (
                          <VisibilityOffIcon
                            onClick={() => {
                              setpassVisibility("text");
                            }}
                          />
                        ) : (
                          <RemoveRedEyeIcon
                            onClick={() => {
                              setpassVisibility("password");
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="w-full flex items-center">
                      <TextInputs
                        type={passVisibility}
                        title={"Confirm Password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        placeholder="Enter your confirmPassword"
                        onInputChange={(value) =>
                          handleInputchange("confirmPassword", value)
                        }
                      />
                      <div className="-ml-8 z-2 bg-white cursor-pointer">
                        {passVisibility === "password" ? (
                          <VisibilityOffIcon
                            onClick={() => {
                              setpassVisibility("text");
                            }}
                          />
                        ) : (
                          <RemoveRedEyeIcon
                            onClick={() => {
                              setpassVisibility("password");
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-full mb-8 ">
                    <select
                      value={formData.department}
                      className="p-2 w-full border rounded-lg border-1 border-[#B1B1B1] "
                      onChange={(e) =>
                        handleInputchange("department", e.target.value)
                      }
                    >
                      <option>Select Category</option>
                      {department.map((items, index) => (
                        <option key={index} value={items.depart}>
                          {items.section}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  {/* <div className="w-full">
                    <TextInputs
                      type={"password"}
                      title={"Password"}
                      name="password"
                      value={formData.value}
                      placeholder="Password"
                      onInputChange={(value) =>
                        handleInputchange("password", value)
                      }
                    />
                  </div> */}
                </div>
                <div>
                  {/* Form inputs */}
                  {/* Error Toast component */}
                  <ErrorToast
                    message={toastMessage}
                    open={showErrorToast}
                    onClose={() => setShowErrorToast(false)}
                  />
                </div>

                <div className="md:flex justify-center items-center">
                  <div className="flex justify-center w-2/3">
                    <button
                      onMouseDown={() => {
                        setclick(true);
                      }}
                      onMouseUp={() => {
                        setclick(false);
                      }}
                      onClick={handleSignupClick}
                      className={`w-full p-1 bg-[#118615]   ${
                        click ? "opacity-75" : "opacity-100"
                      } text-xl text-white rounded-md`}
                    >
                      Sign up
                    </button>
                  </div>
                </div>
                <div className="text-sm text-center my-2 text-[#666666]">
                  Already have an account?
                  <Link to={"/newlogin"} className="text-[#118615] underline ">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="text-xs md:text-xl  mt-4 font-bold text-[#118615]">
            "Empower your journey, charge up for sustainability!"
          </div>
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center backdrop-filter backdrop-blur-lg bg-black bg-opacity-75">
          <div className="p-6 rounded-lg max-w-xl w-full mx-2 shadow-md bg-white">
            <div className="flex justify-between items-center mb-2">
              <p
                onClick={handleEditNumber}
                className="font-underline cursor-pointer"
              >
                <span className="underline">Not you?</span> +91
                {formData.mobileNo}
              </p>
              <button
                onClick={() => setShowOTP(false)}
                className="text-zinc-600 hover:text-zinc-900 bg-transparent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <p className="text-2xl mb-2 font-semibold">Verification Code</p>
            <div>
              <label
                htmlFor="otp"
                className="font-semibold text-md text-black text-center"
              >
                Enter your OTP
              </label>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="otp-container my-2 justify-between"
              />
            </div>
            <div>
              {timer > 0 ? (
                <p>Resend Code in {timer} seconds</p>
              ) : (
                <button
                  onClick={handleEditNumber}
                  className="mt-2 bg-[#118615] rounded-lg"
                >
                  Resend Now
                </button>
              )}
            </div>
            <div className="space-y-7">
              <div className="flex space-x-2"></div>
              <button
                onClick={onOTPVerify}
                className="w-full bg-black text-white font-semibold py-2 rounded-lg sm:w-auto"
              >
                <span>Verify OTP</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default New_register;

{
  /* <div className="fixed inset-0 flex items-center justify-center backdrop-filter backdrop-blur-none">
          <div className="p-6 rounded-lg max-w-xl w-full mx-2 shadow-md">
            <div className="flex justify-between items-center mb-4">
              <p
                onClick={handleEditNumber}
                className="font-underline cursor-pointer"
              >
                <span className="underline">Not you?</span> +91
                {formData.mobileNo}
              </p>
              <button
                onClick={() => setShowOTP(false)}
                className="text-zinc-600 hover:text-zinc-900"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <p className="text-2xl mb-2 font-semibold">Verification Code</p>
            <div>
              <label
                htmlFor="otp"
                className="font-semibold text-md text-black text-center"
              >
                Enter your OTP
              </label>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="otp-container my-2 justify-between"
              />
            </div>
            <div>
              {timer > 0 ? (
                <p>Resend Code in {timer} seconds</p>
              ) : (
                <button
                  onClick={handleEditNumber}
                  className="underline font-bold"
                >
                  Resend Now
                </button>
              )}
            </div>
            <div className="space-y-7">
              <div className="flex space-x-2"></div>
              <button
                onClick={onOTPVerify}
                className={`w-full bg-black text-white font-semibold py-2 rounded-lg`}
              >
                <span>Verify OTP</span>
              </button>
            </div>
          </div>
        </div> */
}
