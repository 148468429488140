import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import "./Support.css";
import { Margin } from "@mui/icons-material";
import Sidebar from "../Sidebar/Sidebar";
import PlusContainer from "./PlusContainer";
// import PlusContainer from './Plus1';
// import { Plus } from 'lucide-react';

const Support = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [message, setMessage] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [collapsed, setCollapsed] = useState(true);
  const [rtl, setRtl] = useState(false);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log("Selected Option:", selectedOption);
    console.log("Message:", message);
    // Reset form fields
    setSelectedOption("");
    setMessage("");
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      <Sidebar collapsed={collapsed} rtl={rtl} />
      {/* <main className="container " style={{ height: "100%" }}> */}
      <main className="w-full py-8 px-4 lg:ml-20 mt-12 overflow-hidden">
        <h1 className="my-16 text-center font-bold text-[green]">
          How We Can Help You?
        </h1>
        <div className="background-image"></div>
       
        <div className="my-16">
          <div className="flex justify-center flex-wrap  gap-4">
            <div className="">
              {/* Add margin-bottom class */}
              <PlusContainer title="Contact Details" />
            </div>
            {/* <div className="">
              <PlusContainer title="" choose="Objective" />
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Support;
